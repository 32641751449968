import React, {useEffect} from 'react';
import {useForm, Controller, SubmitHandler} from "react-hook-form";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Trans, useTranslation} from "react-i18next";
import {AuthPagesWrapper} from "../components/AuthPagesWrapper/AuthPagesWrapper";
import {themeColors} from "../styles/theme";
import {useAuth} from "../context/authContext";
import {Link, useLocation, useNavigate} from "react-router-dom";
import LoadingSpinner from "../components/Spinner/LoadingSpinner";
import {ENV} from "../utils/env";


type FormValuesType = {
  email: string,
  password: string,
  rememberMe: boolean
}

export const SignInPage = () => {
  const {loading: signingIn, token, runLogin} = useAuth();
  const [showPassword, setPasswordVisibility] = React.useState<boolean>(false);
  const {handleSubmit, control} = useForm<FormValuesType>();
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      // const to = location.state.from === 'auth' ? '/' : -1;
      navigate('/');
    }
  }, [token])

  const onSubmit: SubmitHandler<FormValuesType> = async ({email, password, rememberMe}) => {
    await runLogin(email, password)
  };

  const handleClickShowPassword = () => {
    setPasswordVisibility(prevState => !prevState);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <AuthPagesWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography component="h1" variant="h5">
            {t('login-form.title')}
          </Typography>
          <Typography component="p">
            {t("login-form.title-message")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              rules={{required: true}}
              render={({field, fieldState, formState}) =>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={t('login-form.email')}
                  autoFocus
                  onChange={field.onChange}
                  sx={{background: themeColors.white}}
                />}
            />
            <Controller
              name="password"
              control={control}
              rules={{required: true}}
              render={({field, fieldState, formState}) =>
                <FormControl sx={{my: 1, width: '100%'}} variant="outlined" required>
                  <InputLabel htmlFor="login-form-password">{t('login-form.password')}</InputLabel>
                  <OutlinedInput
                    id="login-form-password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={field.onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('login-form.password')}
                    sx={{background: themeColors.white}}
                  />
                </FormControl>
              }
            />
            <Grid
              container
              justifyContent="flex-end"
              sx={{mb: 2}}
            >
              {/*< Grid item>*/}
              {/*  <Link to="/reset-password">*/}
              {/*    {t('login-form.forgot-password')}*/}
              {/*  </Link>*/}
              {/*</Grid>*/}
            </Grid>
            <Divider/>
            <Grid container justifyContent="end">
              {/*<Controller*/}
              {/*  name="rememberMe"*/}
              {/*  control={control}*/}
              {/*  render={({field, fieldState, formState}) =>*/}
              {/*    <FormControlLabel*/}
              {/*      control={<Checkbox value="remember" color="primary"/>}*/}
              {/*      label={t('login-form.remember-me')}*/}
              {/*      onChange={field.onChange}*/}
              {/*    />*/}
              {/*  }*/}
              {/*/>*/}
              <Button
                  type="submit"
                  variant="contained"
                  sx={{mt: 3, mb: 2}}
                  style={{background: themeColors.purple}}
                  disabled={signingIn}
              >
                {
                  signingIn ?
                      <div style={{padding: '3px 13px'}}>
                        <LoadingSpinner
                            size="20px"
                            transparent
                        />
                      </div>
                      : t('login-form.submit-button')
                }
              </Button>
            </Grid>
            <Grid container sx={{whiteSpace: "pre-wrap"}} justifyContent="center">
              <Trans i18nKey="login-form.dont-have-account-link">
                Don't have an account? <Link to={`mailto:${ENV.REACT_APP_REPLAN_SALES_EMAIL}`}>Mail to
                us</Link>
              </Trans>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </AuthPagesWrapper>
  )
}