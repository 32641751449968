import {
  AxiosRequestConfig,
  AxiosResponse,
  Axios,
  Method,
  ResponseType,
  AxiosError,
} from "axios";
import {Buffer} from 'buffer';
import axiosClient from "./AxiosConfig";
import {safelyParseJson, safelyStringifyJson} from "../utils/utils";
import {ENV} from "../utils/env";


export interface UserToken {
  accessToken: string,
  expires: number,
  scope: string,
  tokenType: string,
  refreshToken: string
}

export interface RestConfig {
  restClientId: string;
  restClientSecret: string;
  userToken?: UserToken
}

export type ApiError = {
  error: string | {
    error: string;
    error_description: string;
  };
  error_description: string;
  status: number;
}

export class OAuthResponse implements UserToken {
  constructor(
    public accessToken: string,
    public expires: number,
    public scope: string,
    public tokenType: string,
    public refreshToken: string
  ) {
  }
}

type FetchArguments = {
  method: Method,
  path: string,
  data?: string | { [key: string]: any },
  fetchOptions?: AxiosRequestConfig
}

export class RestClient {
  _axios: Axios;
  _config: RestConfig;

  private token: UserToken | null;

  constructor(config: RestConfig) {
    this._axios = axiosClient;
    this._config = config;

    const token = localStorage.getItem('userToken');
    this.token = token ? JSON.parse(token) : null;
  }

  public async login(username = '', password = ''): Promise<OAuthResponse | ApiError> {
    const body = new URLSearchParams();
    body.append("grant_type", "password");
    body.append("username", username);
    body.append("password", password);

    const postOptions: AxiosRequestConfig = {
      headers: this._baseAuthHeaders(),
      responseType: "json",
    };

    return await this.axios
      .post<OAuthResponse>("/oauth/token", body, postOptions)
      .then(this.checkStatus)
      .then((data: any) => {
        return new OAuthResponse(
          data.access_token,
          data.expires_in,
          data.scope,
          data.token_type,
          data.refresh_token
        );
      })
      .catch((error: AxiosError) => {
        return {
          error: error?.response?.data,
          error_description: `${error.message}`,
          status: error?.response?.status
        } as ApiError;
      });
  }

  public async refreshToken(): Promise<OAuthResponse | null | ApiError> {
    const refreshToken = this.token?.refreshToken;
    if (!refreshToken) return null;

    const body = new URLSearchParams();
    body.append("grant_type", "refresh_token");
    body.append("refresh_token", encodeURIComponent(refreshToken));

    return await this.axios
      .post<OAuthResponse>("/oauth/token", body)
      .then(this.checkStatus)
      .then((data: any) => JSON.parse(data))
      .then((data: any) => {
        return new OAuthResponse(
          data.access_token,
          data.expires_in,
          data.scope,
          data.token_type,
          data.refresh_token
        );
      })
      .catch((error: AxiosError) => {
        return {
          error: error?.response?.data,
          error_description: `${error.message}`,
          status: error?.response?.status
        } as ApiError;
      });
  }

  public logout(): Promise<any> {
    const body = new URLSearchParams();
    body.append("token", this.token?.accessToken || "");

    const postOptions: AxiosRequestConfig = {
      headers: this._baseAuthHeaders(),
    };

    const response = this.axios
      .post<any>("/oauth/revoke", body, postOptions)
      .then(this.checkStatus);
    return response;
  }

  public uploadFiles<T>(
    path: string,
    data: FormData,
    fetchOptions?: AxiosRequestConfig
  ) {
    const settings: AxiosRequestConfig = {
      method: "POST",
      url: path,
      headers: {
        Accept: "application/json",
      },
      data: data,
      ...fetchOptions,
    };
    return this.axios
      .request(settings)
      .then(this.checkStatus)
      .then((response) => safelyParseJson(response))
      .catch((error: AxiosError) => {
      return {
        error: error?.response?.data,
        error_description: `${error.message}`,
        status: error?.response?.status
      };
    });
  }

  public fetch<T>({
                    method,
                    path,
                    data = {},
                    fetchOptions
                  }: FetchArguments): Promise<T> {
    const settings: AxiosRequestConfig = {
      method,
      url: path,
      ...fetchOptions,
    };

    if (!settings.headers) {
      settings.headers = {};
    }

    // settings.headers["Authorization"] = `Bearer ${this.token?.accessToken}`;

    if (method === "POST" || method === "PUT") {
      settings.data = data;
      // const params = new URLSearchParams();
      // Object.entries(data).forEach(([key, val]) => params.append(key, val as any));
      // settings.data = params; //JSON.stringify(data);
      if (!settings.headers["Content-Type"]) {
        settings.headers["Content-Type"] = "application/json; charset=UTF-8";
      }
    }
    if (method === "GET" && data && Object.keys(data).length > 0) {
      const url = new URL(path, this.axios.defaults.baseURL);
      Object.entries(data).map(([key, val]) => url.searchParams.append(key, val));
      settings.url = url.toString();
    }
    const responseType: ResponseType =
      fetchOptions && fetchOptions.responseType
        ? fetchOptions.responseType
        : "json";
    switch (responseType) {
      case "text":
        settings.headers["Accept"] = "text/html";
        settings.responseType = "text";
        break;
      case "json":
        settings.headers["Accept"] = "application/json";
        settings.responseType = "json";
        break;
    }
    return this.axios
      .request(settings)
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => {
        return {
          error: safelyStringifyJson(error?.response?.data),
          error_description: `${error.message}`,
          status: error?.response?.status
        };
      })
  }

  _baseAuthHeaders(): { [header: string]: string } {
    return {
      Authorization:
        "Basic " +
        base64encode(
          this._config.restClientId + ":" + this._config.restClientSecret
        ),
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    };
  }

  get axios(): Axios {
    return this._axios;
  }

  private checkStatus<T>(response: AxiosResponse<T>): Promise<T> {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject({message: response.statusText, data: {...response.data}});
    }
  }
}

export function base64decode(str: string): string {
  return Buffer.from(str, "base64").toString("binary");
}

export function base64encode(str: string): string {
  return Buffer.from(str, "binary").toString("base64");
}

const restClient = new RestClient({
  restClientId: ENV.REACT_APP_API_CLIENT_ID as string,
  restClientSecret: ENV.REACT_APP_API_CLIENT_SECRET as string,
});

export default restClient;
