import {useEffect} from "react";
import {removeEntity} from "../../redux/entity/entity-reducer";
import {useDispatch} from "react-redux";
import {useLayersInteractivity} from "../../hooks/map/useLayersInteractivity";
import {removedFeaturesHighlighting} from "../../redux/map/map-reducer";
import {clearedSelectedMapFeatures} from "../../redux/showInfo/showInfoReducer";


type Props = {
    children: React.ReactElement;
    entityName: string;
}

const EntityManagerCleaner: React.FC<Props> = ({children, entityName}) => {
    const {setDrawing} = useLayersInteractivity();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(removeEntity(entityName));
            setDrawing(false);

            dispatch(removedFeaturesHighlighting());
            dispatch(clearedSelectedMapFeatures());
        }
    }, []);

    return (
        <>
            {children}
        </>
    )
}

export default EntityManagerCleaner;