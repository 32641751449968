import React from "react";
import Tooltip from "@mui/material/Tooltip";
import {Link, Outlet, useMatch} from "react-router-dom";
import {useTheme} from "../../context/themeContext";
import '../../project-modes/PublicTransitMode/TransitManagement/transit-manager.scss';


type Props = {
    tabs: {
        url: string;
        title: string;
        icon?: JSX.Element;
        path: string | undefined;
        testId: string;
    }[];
}

const VerticalTabs = ({tabs}: Props) => {
    const {theme} = useTheme();
    //TODO update?
    const match = useMatch('/:projectId/:mode/:entity');
    const mode = match?.params?.entity;

    return (
        <div className={`transit-manager ${theme}`}>
            <nav className="transit-manager__menu">
                {
                    tabs.map(({url, title, icon, path, testId}, ind) => {
                        return (
                            <Tooltip key={ind} title={title} placement="right">
                                <Link
                                    to={url}
                                    className={`transit-manager__link transit-manager__link_icon ${mode === path ? 'active' : ''}`}
                                >
                                    <div
                                        className={`transit-manager__tab ${icon ? 'transit-manager__tab_icon' : ''}`}
                                        data-testid={testId}
                                    >
                                        {
                                            icon
                                                ? icon
                                                : <div className="transit-manager__tab-title">
                                                    {title}
                                                </div>
                                        }
                                    </div>
                                </Link>
                            </Tooltip>
                        )
                    })
                }
                <div className="transit-manager__tab transit-manager__tab_empty"></div>
            </nav>
            <main className="transit-manager__main">
                <Outlet/>
            </main>
        </div>
    )
}

export default VerticalTabs;