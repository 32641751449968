import React from "react";
import Sources from "../../../components/map/Sources";
import MapboxGlLayers from "../../../components/map/MapboxGlLayers/MapboxGlLayers";
import DeckGlLayers from "../../../components/map/DeckGlLayers/DeckGlLayers";
import MapLegend from "../MapLegend/MapLegend";
import MapCoordinatesControl from "../../../components/map/Controls/MapCoordinatesControl/MapCoordinatesControl";
import MapContainer, {MapContainerProps} from "./MapContainer";
import Drawer, {DrawerProps} from "../../../components/map/Drawer/Drawer";
import {TripsLayerContextProvider} from "../../../context/tripsLayerContext";


export type MapContainerWithSourcesProps = {
    isDrawerEnabled?: boolean;
    children?: React.ReactNode;
} & MapContainerProps & DrawerProps;

const MapContainerWithSources = ({
                                     isDrawerEnabled = false,
                                     drawControls,
                                     onFeaturesDraw,
                                     onDrawModeChange,
                                     editableFeatureTypes,
                                     children,
                                     ...rest
                                 }: MapContainerWithSourcesProps) => {
    return (
        <MapContainer {...rest}>
            <Sources/>
            <MapboxGlLayers/>
            <TripsLayerContextProvider>
                <DeckGlLayers/>
            </TripsLayerContextProvider>
            <MapLegend/>
            <MapCoordinatesControl
                className="coordinates-map-control"
                position='bottom-left'
            />
            {/*TODO move to Drawer*/}
            {isDrawerEnabled &&
                <Drawer
                    drawControls={drawControls}
                    onDrawModeChange={onDrawModeChange}
                    onFeaturesDraw={onFeaturesDraw}
                    editableFeatureTypes={editableFeatureTypes}
                />
            }
            {children}
        </MapContainer>
    )
}

export default MapContainerWithSources;