import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import mapApi from "../../api/mapApi";
import {isErrorResponse} from "../../utils/utils";
import mapboxgl from "mapbox-gl";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectFeaturesWithParentsByEntityName,
    selectRedirectOnFeatureSelection
} from "../../redux/selectors/selectors";
import {GeoJsonGeometryTypes} from "geojson";
import {MapboxGeoJSONFeatureWithProperties} from "../../redux/map/types";
import {setFeatureWithParent} from "../../redux/entity/entity-reducer";


type Props = {
    getUrlFunc?: ({parentId, childId}: {
        parentId?: string | number;
        childId: string | number;
        featureType: GeoJsonGeometryTypes,
        entityName: string
    }) => void;
    findParent?: boolean;
};

type LoadArgs = {
    entityId: string;
    entityName: string;
    findParent?: boolean;
}

type ReturnType = {
    navigateOnFeatureSelection: (event: mapboxgl.MapLayerMouseEvent, isDrawing?: boolean) => void;
}

export const useNavigationOnFeaturesSelection = ({getUrlFunc, findParent = false}: Props = {}): ReturnType => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const redirectOnFeatureSelection = useTypedSelector(selectRedirectOnFeatureSelection);
    const featuresWithParentByEntityName = useTypedSelector(selectFeaturesWithParentsByEntityName);

    const loadFeature = useCallback(async ({entityId, entityName, findParent}: LoadArgs): Promise<{
        feature: any;
        parent?: any;
    }> => {
        const response = {} as { feature: any; parent?: any; };

        const abortController = new AbortController();
        const feature = await mapApi.getFeatureById(entityId, entityName, abortController.signal);
        if (!isErrorResponse(feature)) {
            response['feature'] = feature;
        }

        if (findParent) {
            const abortController = new AbortController();
            const parent = await mapApi.getFeatureById(entityId, entityName, abortController.signal, findParent);
            if (!isErrorResponse(parent)) {
                response['parent'] = parent;
            }
        }

        dispatch(setFeatureWithParent({
            entityName,
            feature,
            ...(findParent ? {parent: feature} : {})
        }));

        return response;
    }, [])

    //TODO pass URL path as arguments => remove hardcode
    const navigateOnFeatureSelection = useCallback(async (event: mapboxgl.MapLayerMouseEvent, isDrawMode?: boolean) => {
        const {features, originalEvent: {shiftKey}} = event;

        if (features?.length) {
            const feature = features[0] as MapboxGeoJSONFeatureWithProperties;
            const {geometry: {type}, properties: {id, entityName}} = feature;

            if (isDrawMode || !redirectOnFeatureSelection) {
                return;
            }

            const shouldRedirectToEntityEditor = !shiftKey;
            if (shouldRedirectToEntityEditor) {
                const {parent} = await loadFeature({entityId: id, entityName, findParent})

                if (!isErrorResponse(parent)) {
                    navigate(getUrlFunc?.({
                        parentId: parent['id'] as string,
                        childId: id!,
                        featureType: type,
                        entityName
                    }) ?? `./${id}/edit`,
                        {state: {prevLocation: location.pathname}}
                    );
                }
            }
        } else {
            const shouldRedirectToEntitiesList = !shiftKey;
            //TODO is it OK that the whole app rerenders after redirect?
            if (shouldRedirectToEntitiesList) {
                navigate(`./`);
                return;
            }
        }
    }, [redirectOnFeatureSelection, featuresWithParentByEntityName]);

    return {navigateOnFeatureSelection};
}