import {
    ICoordinates, ICoordinatesMapbox, SelectedFeatureProperties,
} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Agents from "../../viz/components/Agents";


export interface ShowInfoStoreState {
    agentInfo: string | null | Agents;
    linkInfo: string | null;
    showActivitiesFilter: boolean;
    coordinates: ICoordinates;
    coordinatesMapbox: ICoordinatesMapbox;
    selectedFeatures: SelectedFeatureProperties[];
    lngLat: {
        lng: number | null;
        lat: number | null;
    }
}

const initialState: ShowInfoStoreState = {
    agentInfo: null,
    linkInfo: null,
    showActivitiesFilter: false,
    coordinates: {x: 0, y: 0},
    coordinatesMapbox: {x: 29.7240962, y: 60.0171988, zoom: 10},
    selectedFeatures: [],
    lngLat: {
        lng: null,
        lat: null
    }
};

const showInfoSlice = createSlice({
    name: 'showInfo',
    initialState,
    reducers: {
        setSelectedMapFeatures(state, action: PayloadAction<
            SelectedFeatureProperties[] | undefined
        >) {
            state.selectedFeatures = action.payload ?? [] as any;
        },
        clearedSelectedMapFeatures(state, action: PayloadAction) {
            state.selectedFeatures = initialState.selectedFeatures as any;
            state.lngLat = initialState.lngLat;
        },
        setClickLngLat(state, action: PayloadAction<{ lngLat: { lng: number, lat: number } }>) {
            const {lngLat} = action.payload;
            state.lngLat = lngLat;
        },
        carInfoAction(state, action: PayloadAction<Agents | string>) {
            state.linkInfo = null;
            state.showActivitiesFilter = false;
            state.agentInfo = action.payload;
        },
        linkInfoAction(state, action: PayloadAction<string>) {
            state.agentInfo = null;
            state.showActivitiesFilter = false;
            state.linkInfo = action.payload;
        },
        showActivitiesFilterAction(state, action: PayloadAction<boolean>) {
            state.agentInfo = null;
            state.linkInfo = null;
            state.showActivitiesFilter = true;
        },
        clearInfoAction(state, action: PayloadAction) {
            state.agentInfo = null;
            state.linkInfo = null;
            state.showActivitiesFilter = false;
        },
        coordinatesToShowAction(state, action: PayloadAction<ICoordinates>) {
            state.coordinates = {
                x: action.payload.x,
                y: action.payload.y,
            }
        },
        coordinatesToMapboxAction(state, action: PayloadAction<ICoordinatesMapbox>) {
            state.coordinatesMapbox = action.payload;
        },
    },
});

export const {
    setSelectedMapFeatures,
    clearedSelectedMapFeatures,
    setClickLngLat,
    carInfoAction,
    linkInfoAction,
    showActivitiesFilterAction,
    clearInfoAction,
    coordinatesToShowAction,
    coordinatesToMapboxAction,
} = showInfoSlice.actions;

export default showInfoSlice.reducer
