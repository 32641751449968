import React, {useCallback, useEffect} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {
    PublicTransitLayerEntityName,
    PublicTransitRouteEntity,
    PublicTransitScheduleElementEntity
} from "../../../../api/entities/replancity_PublicTransitLine";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import {removeEntity} from "../../../../redux/entity/entity-reducer";
import {useDispatch} from "react-redux";
import {TransitRouteContext} from "../../types";


const DepartureEdit = () => {
    const entityName = PublicTransitLayerEntityName.DEPARTURE;
    const {departureId} = useParams<any>();
    const {scheduleId} = useOutletContext<TransitRouteContext>();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(removeEntity(entityName));
        }
    }, [])

    //TODO update routes, because "departures/new" is a single step and '../' not navigates to "departures" as expected
    const onSaveFn = useCallback((entity: PublicTransitRouteEntity) => {
        if (!departureId) {
            return `../${entity.id}/edit`;
        }
    }, [departureId]);

    const onDeleteFn = useCallback((entity: PublicTransitScheduleElementEntity) => '../departures', []);

    const saveArgsFn = useCallback((entity: PublicTransitScheduleElementEntity) => ({
        publicTransitSchedule: {id: scheduleId}
    }), [scheduleId]);

    return (
        <EntityEditor<PublicTransitScheduleElementEntity>
            entityId={departureId!}
            entityName={entityName}
            layerId={''} // make optional?
            saveFnArgs={saveArgsFn}
            getUrlOnSaveFn={onSaveFn}
            getUrlOnDeleteFn={onDeleteFn}
        />
    )
}

export default DepartureEdit;