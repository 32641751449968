import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MetadataPropertyEntity} from "../../api/entities/replancity_MetadataProperty";
import {DataById, FeatureProperties} from "../map/types";

type State = {
    dataByEntityName: any;
    metadataByEntityName: DataById<MetadataPropertyEntity[]>;
    featuresWithParentByEntityName: {
        [key: string]: {
            parent?: { [key: string]: any },
            feature: GeoJSON.Feature<GeoJSON.Geometry, Omit<FeatureProperties, 'layerId'>>
        };
    };
    isChanged: boolean;
}

export const initialState: State = {
    dataByEntityName: {},
    metadataByEntityName: {},
    featuresWithParentByEntityName: {},
    isChanged: false,
}

const entitySlice = createSlice({
    name: 'entity',
    initialState,
    reducers: {
        setEntity(state, action: PayloadAction<{ entityName: string; entity?: any; }>) {
            const {entityName, entity} = action.payload;
            state.dataByEntityName[entityName] = entity ?? {};
        },
        setEntityMetadata(state, action: PayloadAction<{ entityName: string; metadata: MetadataPropertyEntity[]; }>) {
            const {entityName, metadata} = action.payload;
            state.metadataByEntityName[entityName] = metadata ?? {};
        },
        setFeatureWithParent(state, action: PayloadAction<{
            entityName: string;
            parent?: { [key: string]: any };
            feature?: GeoJSON.Feature<GeoJSON.Geometry, Omit<FeatureProperties, 'layerId'>>;
        }>) {
            const {entityName, parent, feature} = action.payload;
            state.featuresWithParentByEntityName[entityName] = {
                ...state.featuresWithParentByEntityName[entityName],
                ...(parent ? {parent} : {}),
                ...(feature ? {feature} : {}),
            };
        },
        removeEntity(state, action: PayloadAction<string>) {
            const {[action.payload]: deletedEntity, ...restEntities} = state.dataByEntityName;
            const {[action.payload]: deletedMetaData, ...restMetaData} = state.metadataByEntityName;
            state.dataByEntityName = restEntities;
            state.metadataByEntityName = restMetaData;
        },
        removeFeature(state, action: PayloadAction<{ entityName: string; }>) {
            const {entityName} = action.payload;
            if (state.featuresWithParentByEntityName[entityName]) {
                const {[entityName]: removed, ...rest} = state.featuresWithParentByEntityName;
                state.featuresWithParentByEntityName = rest;
            }
        },
        setEntityChanged(state, action: PayloadAction<boolean>) {
            state.isChanged = action.payload;
        },
    },
});

export const {
    setEntity,
    setEntityMetadata,
    setFeatureWithParent,
    removeEntity,
    removeFeature,
    setEntityChanged,
} = entitySlice.actions;

export default entitySlice.reducer
