import {SetStateAction} from "react";
import {Dispatch} from "redux";
import mapboxgl, {GeoJSONSourceRaw, MapboxGeoJSONFeature, MapLayerMouseEvent} from "mapbox-gl";
import {DeckLayerType, MapboxLayerType} from "../api/enums/enums";
import {
  DataById,
  DateType,
  DeckLayer,
  FeatureProperties,
  FeatureState,
  LayerType,
  LayerTypes,
  LayerVisibility,
  MapboxGeoJSONFeatureWithProperties,
  NamedLayer,
  SourceItem
} from "../redux/map/types";
import * as turf from "@turf/turf";
import {EntityMapLayer} from "../api/entities/replancity_MapLayer";
import {SimulationState} from "../api/entities/replancity_RunnedAlgorithm";
import {DrawModeWithRectangle} from "../components/map/Drawer/types";
import {MapboxMap} from "react-map-gl/src/types";
import cloneDeep from "lodash/cloneDeep";
import mapApi from "../api/mapApi";
import {isErrorResponse} from "./utils";
import {flewToPoint, requireToHighlightFeatures} from "../redux/map/map-reducer";


//TODO remove if unused in future
export const createGeoJsonSource = (
  data?: GeoJSON.FeatureCollection
): GeoJSONSourceRaw => ({
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: [],
    ...(data || {})
  }
} as GeoJSONSourceRaw);

export const createFeatureCollection = (): GeoJSON.FeatureCollection => ({
  type: "FeatureCollection",
  features: []
})

export const featureToFeatureCollection = (feature): GeoJSON.FeatureCollection => ({
  type: "FeatureCollection",
  features: [{...feature}]
})

export const jsonGeometryStringToFeatureCollection = (jsonGeometryString: string, properties = {}) => {
  const geometry = JSON.parse(jsonGeometryString);

  const feature = geometryToFeature(geometry);
  //TODO this is temporary. Move to constant
  feature.properties = properties;
  return featureToFeatureCollection(feature);
}

export const jsonFeatureStringToFeatureCollection = (jsonFeatureString: string, properties = {}) => {
  const feature = JSON.parse(jsonFeatureString);

  feature.properties = properties;
  return featureToFeatureCollection(feature);
}

export const updateGeoJsonSourceData = (
  data: GeoJSON.FeatureCollection
): GeoJSONSourceRaw => ({
  type: "geojson",
  data
} as GeoJSONSourceRaw);

export const toSourceItem = ({
                               id,
                               type,
                               displayName,
                               entityName,
                               showByDefault,
                               order,
                               opacity,
                               dayTimeDependent,
                               calculationState = '',
                               clusterize,
                               ...rest
                             }: EntityMapLayer): SourceItem => {
  return {
    id,
    name: displayName ?? id,
    type: type as MapboxLayerType | DeckLayerType,
    entityName,
    visibility: showByDefault ? LayerVisibility.VISIBLE : LayerVisibility.NONE,
    order,
    opacity,
    dayTimeDependent,
    ...(calculationState ? {calculationState} : {}),
    cluster: !!clusterize,
    ...(clusterize ? {
      clusterMaxZoom: 14,
      clusterRadius: 50
    } : {}),
    clickable: rest.clickable,
    queryable: rest['queryable'] ?? true,
    visibleInLayersList: rest['visibleInLayersList'] ?? true,
    ...(rest.labelFieldName ? {labelFieldName: rest.labelFieldName} : {}),
    extrudable: false, // remove when it appears in source item
    updateOnFeatureSelection: rest.updateOnFeatureSelection,
    isReloadRequired: false,
    showDirection: rest.showDirection,
    group: rest.group,
    zoomDependent: !!rest?.zoomDependent
  };
};

const colorBehavior: mapboxgl.Expression = [
  'case',
  ['!=', ['feature-state', FeatureState.HIGHLIGHTED], null],
  "#db08e8",
  ['!=', ['feature-state', FeatureState.HOVERED], null],
  "#dcd0c6",
  // ['!=', ['feature-state', 'click'], null],
  // "#e00d0d",
  ['string', ['get', 'color'], "#e59e60"]
]

const pointStrokeBehavior: mapboxgl.Expression = [
  'case',
  ['!=', ['feature-state', 'hover'], null],
  "#e00d0d",
  "#fff",
]

const lineWidthBehavior: mapboxgl.Expression = [
  "interpolate",
  [
    "exponential",
    1.5
  ],
  [
    "zoom"
  ],
  12,
  0.5,
  14,
  2,
  18,
  18
  // 'case',
  // ['!=', ['feature-state', 'highlighted'], null],
  // ['*', 2, ['number', ['get', 'width']]],
  // ['number', ['get', 'width']]
]

//TODO this is to reduce opacity of unhighlighted features => set 2nd 1 to 0.2 or whatever
const lineOpacityBehavior: mapboxgl.Expression = [
  'case',
  ['==', ['feature-state', 'highlighted'], true],
  1, //source.opacity instead of 1?
  1
]

const getLayerCommonProperties = (source: SourceItem, layerType: LayerType) => ({
  id: source.id,
  layerName: source.name,
  layerType,
  source: source.id,
  layout: {visibility: source.visibility},
  paint: {},
  order: source.order,
  loading: false,
  isClusterLayer: false,
  clickable: source.clickable, // required to change layers clickability when necessary. default clickability can be restored by source.clickable
  visibleInLayersList: source.visibleInLayersList
})

const createMapBoxLayer = (source: SourceItem): NamedLayer => {
  const {paint, layout, ...restProperties} = getLayerCommonProperties(source, LayerType.MAPBOXGL);

  switch (source.type) {
    case MapboxLayerType.STOPS:
    case MapboxLayerType.POINT:
      return {
        ...restProperties,
        type: "circle",
        filter: ['!', ['has', 'point_count']],
        paint: {
          ...paint,
          // 'circle-color': '#11b4da',
          "circle-color": colorBehavior,
          'circle-opacity': source.opacity,
          // 'circle-radius': 4,
          'circle-radius': [
            // 'case',
            //   ['!=', ['feature-state', 'click'], null],
            //   ['*', 1.5, ['number', ['get', 'width'], 1]],
            //   ['get', 'width']

            "interpolate", ["linear"], ["zoom"],
            // zoom is 14 (or less) -> circle radius will be 5px
            14, ['get', 'width'],
            // zoom is 10 (or greater) -> circle radius will be 5px
            14.5, 8

          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': pointStrokeBehavior
        },
        layout: {
          ...layout,
          // 'circle-sort-key': source.order
        },
      };
    case MapboxLayerType.NETWORK:
    case MapboxLayerType.LINES:
    case MapboxLayerType.LINE:
    case MapboxLayerType.LINESTRING:
      return {
        ...restProperties,
        type: "line",
        paint: {
          ...paint,
          'line-color': colorBehavior,
          'line-opacity': lineOpacityBehavior, //source.opacity,
          'line-width': lineWidthBehavior,
          'line-offset': ['number', ['get', 'offset'], 2],
          // "line-dasharray": [
          //   "match", ['feature-state', 'click'],
          //   'true', ["literal", [2, 1]],
          //   // 2, ["literal", [2, 2]],
          //   // 3, ["literal", [3, 2]],
          //   ["literal", [1, 0]]
          // ]
        },
        layout: {
          ...layout,
          'line-join': 'bevel',
          'line-cap': 'butt',
          "line-sort-key": source.order
        },
      };
    case MapboxLayerType.POLYGON: {
      if (source.extrudable) {
        return {
          ...restProperties,
          type: 'fill-extrusion',
          paint: {
            ...paint,
            'fill-extrusion-color': colorBehavior,
            // 'fill-extrusion-height': ['number', ['get', 'value']],
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              13.05,
              // ['number', ['get', 'value']]
              ['*', ['get', 'value'], 2]
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              13.05,
              ['get', 'value']
              // ['*', ['get', 'value'], 1]
            ],
            'fill-extrusion-opacity': source.opacity,
          },
          layout: {
            ...layout
          }
        }
      }

      return {
        ...restProperties,
        type: 'fill',
        paint: {
          ...paint,
          'fill-color': colorBehavior,
          'fill-opacity': source.opacity,
        },
        layout: {
            ...layout
        }
      }
    }
    case MapboxLayerType.CLICKEDLINES:
      return {
        ...restProperties,
        type: "line",
        paint: {
          ...paint,
          "line-color": "#fbec5d",
          "line-width": lineWidthBehavior, //5,
          'line-opacity': lineOpacityBehavior, //source.opacity,
        },
        layout: {
            ...layout
        }
      };
    case MapboxLayerType.CLICKEDSTOPS:
      return {
        ...restProperties,
        type: "circle",
        paint: {
          ...paint,
          "circle-color": "#44944A",
        },
        layout: {
            ...layout
        }
      };
    default:
      throw Error("Unsupported layer type");
  }
}

export const createLabelsLayer = (source: SourceItem): NamedLayer => {
  const {labelFieldName} = source;
  const {layout, paint, ...properties} = getLayerCommonProperties(source, LayerType.MAPBOXGL);

  return {
    ...properties,
    id: `${source.id}_labels`,
    layerName: `"${source.name}" labels`,
    type: 'symbol',
    layout: {
      ...layout,
      // 'icon-image': 'custom-marker',
      'text-field': ['get', labelFieldName],
      'text-size': [
        "interpolate", ["linear"], ['zoom'],
        // zoom is lower than 14 -> text-size == N
        14, 0,
        // zoom is 14.5 (or lower) -> text-size == N
        14.5, 12,
        // zoom is 15 (or lower) -> text-size == N
        15, 18,
        // zoom is 15.5 (lower or greater) -> text-size == N
        15.5, 24,
      ],
      'text-font': [
        'Open Sans Semibold',
        'Arial Unicode MS Bold'
      ],
      'text-offset': [0, 1.25],
      // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      // 'text-radial-offset': 1.5,
      'text-justify': 'auto',
      'text-anchor': 'top',
      'symbol-avoid-edges': true
    },
    paint: {
      ...paint,
      'text-color': ['string', ['get', 'color'], '#fff'],
      'text-halo-color': '#000',
      'text-halo-width': 8
    },
    visibleInLayersList: false
  }
}

export const createDirectionSymbolLayer = (source: SourceItem): NamedLayer => {
  const {id, layout, paint, ...properties} = getLayerCommonProperties(source, LayerType.MAPBOXGL);

  return {
    ...properties,
    id: `${id}-directions-layer`,
    layerName: `"${source.name}" arrows`,
    type: 'symbol',
    order: 100,
    layout: {
      ...layout,
      'symbol-placement': 'line-center',
      // 'symbol-spacing': 55,
      'icon-image': [
        'case',
        ['in', ['string', ['get', 'oneway'], 'defaultProperty'], ['literal', ['FORWARD', 'BACKWARD']]],
        'iArrowIcon',
        ['in', ['string', ['get', 'oneway'], 'defaultProperty'], ['literal', ['SYMMETRIC', 'ASYMMETRIC']]],
        'iArrowOppositeIcon',
        'iArrowIcon'
      ],
      'icon-size': 0.5,
      // 'symbol-avoid-edges': true,
      'icon-rotate': [
        'case',
        ['in', ['string', ['get', 'oneway']], ['literal', ['FORWARD', 'SYMMETRIC', 'ASYMMETRIC']]],
        0,
        ['==', ['string', ['get', 'oneway']], 'BACKWARD'],
        180,
        0
      ],
    },
    paint: {
      ...paint,
      'icon-opacity': 0.6,
    },
    visibleInLayersList: false
  }
}

const createDeckLayer = (source: SourceItem): DeckLayer => {
  const commonProperties = {
    ...getLayerCommonProperties(source, LayerType.DECKGL),
    order: source.order || 0,
    clickable: false,
    visibleInLayersList: true
  };

  switch (source.type) {
    case 'ARC': {
      return {
        ...commonProperties,
        type: 'arc',
      }
    }
    case 'TRIPS': {
      return {
        ...commonProperties,
        type: 'trips',
      }
    }
    default:
      return {
        ...commonProperties,
        type: source.type,
      }
  }
}

export const createLayer = (
  source: SourceItem,
): NamedLayer | DeckLayer => {
  if (source.type in MapboxLayerType) {
    return createMapBoxLayer(source);
  }
  return createDeckLayer(source);
};

export const createClustersLayer = ({id, opacity, visibility, order}: SourceItem): NamedLayer => ({
  id: `clusters_${id}`,
  type: 'circle',
  layerType: LayerType.MAPBOXGL,
  layerName: `clusters_${id}`,
  source: id,
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': [
      'step',
      ['get', 'point_count'],
      '#51bbd6',
      10,
      '#f1f075',
      50,
      '#bb823a',
      100,
      '#f28cb1'
    ],
    'circle-opacity': opacity,
    'circle-radius': [
      'step',
      ['get', 'point_count'],
      10,
      10,
      15,
      50,
      18,
      100,
      20
    ]
  },
  layout: {visibility},
  order,
  loading: false,
  isClusterLayer: true,
  clickable: false,
  visibleInLayersList: false
})

export const createClustersCountLayer = ({id, opacity, visibility, order}: SourceItem): NamedLayer => ({
  id: `cluster_count_${id}`,
  type: 'symbol',
  layerType: LayerType.MAPBOXGL,
  layerName: `cluster_count_${id}`,
  source: id,
  filter: ['has', 'point_count'],
  paint: {},
  layout: {
    visibility,
    'text-field': ['get', 'point_count_abbreviated'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  },
  order,
  loading: false,
  isClusterLayer: true,
  clickable: false,
  visibleInLayersList: false
})

const name = (val: string, postfix: string) => {
  return `${val}- ${postfix}`;
};

//eslint-disable-next-line
const getType = (type: MapboxLayerType): "fill" | "circle" | "line" => {
  switch (type) {
    case MapboxLayerType.STOPS:
      return "circle";
    case MapboxLayerType.LINES:
    case MapboxLayerType.NETWORK:
      return "line";
    default:
      return "fill";
  }
};


export const getEntityNamesByFeatureIds = (features: GeoJSON.Feature<any>[]): DataById<string> => {
  return features.reduce((acc, cur) => {
    const id = cur.properties?.id;
    if (!acc[id] && cur.properties?.entityName) {
      acc[id] = cur.properties?.entityName;
    }
    return acc;
  }, {});
}

export const getFeaturePropertiesByIds = (features: GeoJSON.Feature<any>[]): DataById<any> => {
  return features.reduce((acc, cur) => {
    const id = cur.properties?.id;
    if (!acc[id]) {
      acc[id] = cur.properties;
    }
    return acc;
  }, {});
}

export const getDateTypesByIds = (allowedTypes: any[]): DataById<DateType> => {
  return allowedTypes.reduce((acc, cur) => {
    acc[cur.id] = {
      name: cur.name,
      fromTime: cur.fromTime,
      toTime: cur.toTime
    }
    return acc;
  }, {})
}

export const isLayerVisibleOnMap = (layer: LayerTypes | undefined): boolean => {
  return layer?.layout?.visibility === LayerVisibility.VISIBLE;
}

export const isLayerVisibleInList = (layer: LayerTypes | undefined): boolean => {
  return !!(!layer?.isClusterLayer && layer?.visibleInLayersList && layer?.id !== 'projectBorders');
}

export const layerHasDontShowState = (state: SimulationState) => state === SimulationState.DO_NOT_SHOW;

export const getFeatureFromEntity = (entity: any, entityName: string): GeoJSON.Feature<GeoJSON.Geometry, FeatureProperties> | null => {
  const [featureType] = entity?.geometry ? entity.geometry.split(' ') : [];
  const jsonGeometry: any = entity['jsonGeometry'] ? JSON.parse(entity['jsonGeometry']) : null;

  if (!featureType || !jsonGeometry) {
    return null;
  }

  let type = 'POINT';
  let coordinates: any = [];
  switch (featureType) {
    case 'POINT':
      type = 'Point';
      coordinates = jsonGeometry[0];
      break;
    case 'LINE':
      type = 'Line';
      coordinates = jsonGeometry;
      break;
    case 'LINESTRING':
      type = 'LineString';
      coordinates = jsonGeometry;
      break;
    case 'POLYGON':
      type = 'Polygon';
      coordinates = [jsonGeometry];
      break;
    default:
      type = 'Point';
  }

  //TODO maybe use entity.featureStateId instead of HEX id
  return {
    type: "Feature",
    id: parseInt(entity.id, 16), // must be number | string, convertable to number
    properties: {
      id: entity.id, // to fix linksInfo onFeatureClick
      featureStateId: parseInt(entity.id, 16), // must be number | string, convertable to number
      layerId: entity?.layerId ?? '',
      entityName: entityName,
      color: entity['color'],
      width: entity['width'],
    },
    geometry: {
      type,
      coordinates
    } as GeoJSON.Geometry
  }
}

export const getFeatureCollectionFromEntities = (entities: any[] | GeoJSON.FeatureCollection<GeoJSON.Geometry, FeatureProperties>, entityName: string): GeoJSON.FeatureCollection<GeoJSON.Geometry, FeatureProperties> => {
  //TODO backend response should be improved => simplify below code

  let features: any = [];
  try {
    features = Array.isArray(entities)
      ? entities?.map(entity => getFeatureFromEntity(entity, entityName))
      : entities?.features.map(feature => {
        const {id, properties: {color, width, featureStateId}, geometry} = feature;
        const {coordinates, type, TYPE} = geometry as any;
        const hexId = id ? parseInt(id.toString(), 16) : '';

        return {
          type: "Feature",
          id: hexId, // must be number | string, convertable to number
          properties: {
            id, // to fix linksInfo onFeatureClick
            featureStateId: hexId, // must be number | string, convertable to number
            entityName,
            color,
            width,
          },
          geometry: {
            type: type ?? TYPE,
            coordinates
          }
        }
      });
  } catch (e) {
    console.error('getFeatureCollectionFromEntities error:', e);
  }

  return getFeatureCollection(features);
}


export const getFeatureCollection = (features: Array<GeoJSON.Feature<GeoJSON.Geometry, FeatureProperties>>): GeoJSON.FeatureCollection<GeoJSON.Geometry, FeatureProperties> => ({
  type: "FeatureCollection",
  features
})

export const getFeatureId = (feature: MapboxGeoJSONFeature | GeoJSON.Feature): string => {
  return feature?.properties?.featureStateId || feature?.properties?.cluster_id;
}

export const isFeatureNotEmptyPoint = (feature: MapboxGeoJSONFeature): boolean => !!getFeatureId(feature);

export const getViewPortCoordinatesFromFeatureCoordinates = (coordinates: GeoJSON.Position[]) => {
  return coordinates.reduce((acc, [lon, lat]) => {
    acc.minLon = Math.min(acc.minLon, lon);
    acc.minLat = Math.min(acc.minLat, lat);
    acc.maxLon = Math.max(acc.maxLon, lon);
    acc.maxLat = Math.max(acc.maxLat, lat);

    return acc;
  }, {
    minLat: 1000,
    maxLat: -1000,
    minLon: 1000,
    maxLon: -1000,
    zoom: 13
  })
}

export const mapEventToPointFeature = (event: MapLayerMouseEvent): GeoJSON.Feature => ({
  "type": "Feature",
  "geometry": {
    "type": "Point",
    "coordinates": Object.values(event.lngLat)
  },
  "properties": {}
})

export const getFeatureFromProperties = (properties: Partial<FeatureProperties>): GeoJSON.Feature<any, Partial<FeatureProperties>> => ({
  type: "Feature",
  geometry: {} as any,
  properties
})

export const geometryToFeature = (geometry: GeoJSON.Geometry): GeoJSON.Feature => ({
  type: "Feature",
  geometry,
  properties: {}
})

export const isDrawMode = (drawMode: DrawModeWithRectangle | undefined) => !!drawMode && drawMode !== 'simple_select';

export const getUniqueFeatures = (features: MapboxGeoJSONFeatureWithProperties[]) => {
  const uniqueIds = new Set();
  const uniqueFeatures: MapboxGeoJSONFeatureWithProperties[] = [];
  for (const feature of features) {
    const id = feature?.properties?.['id'];
    if (id && !uniqueIds.has(id)) {
      uniqueIds.add(id);
      uniqueFeatures.push(feature);
    }
  }
  return uniqueFeatures;
}

export const areCoordinatesExist = (lngLat: { lng: number; lat: number; }): boolean => {
  const {lng, lat} = lngLat;
  return !!lng && !!lat;
}

export const areCoordinatesEqual = (lngLat1: { lng: number; lat: number; }, lngLat2: { lng: number; lat: number; }): boolean => {
  return lngLat1.lng === lngLat2.lng && lngLat1.lat === lngLat2.lat;
}

export const getFeatureCenter = (feature: GeoJSON.Feature<GeoJSON.Geometry>): GeoJSON.Position => {
    const centerPoint: GeoJSON.Feature<GeoJSON.Point> = turf.center(feature as any);
    const {geometry: {coordinates}} = centerPoint;
    return coordinates;
}

export const setFeatureState = ({mapRef, featureStateId, layerId, stateProperty, stateValue = true}:
                                    {
                                      mapRef: MapboxMap;
                                      featureStateId: string;
                                      layerId: string;
                                      stateProperty: string;
                                      stateValue: any;
                                    }
) => {
  mapRef.setFeatureState(
      {
        source: layerId,
        id: featureStateId,
      },
      {
        [stateProperty]: stateValue,
      },
  );
}

export const removeFeatureState = ({mapRef, featureStateId, layerId, stateProperty}:
                                       {
                                         mapRef: MapboxMap;
                                         featureStateId: string;
                                         layerId: string;
                                         stateProperty: string;
                                       }) => {
  mapRef.removeFeatureState({
        source: layerId,
        id: featureStateId,
      },
      stateProperty
  );
}

export const addSourceToFeature = (feature: GeoJSON.Feature<GeoJSON.Geometry, FeatureProperties>, sourceId: string) => {
  const clonedFeature = cloneDeep(feature);
  clonedFeature.properties['layerId'] = sourceId;
  clonedFeature['source'] = sourceId;
  return clonedFeature;
}

export const flyToFeatureAndHighlight = async ({entityId, entityName, feature, layerId, dispatch}: {
  entityId: string;
  entityName: string;
  feature: GeoJSON.Feature<GeoJSON.Geometry, Omit<FeatureProperties, 'layerId'>>;
  layerId: string;
  dispatch: Dispatch<SetStateAction<any>>;
}) => {

  const {properties: {id, featureStateId}} = feature;
  const [lng, lat] = getFeatureCenter(feature);
  dispatch(flewToPoint({lng, lat}));

  dispatch(requireToHighlightFeatures({
    featureProperties: {
      id: id!.toString(),
      featureStateId: featureStateId.toString(),
      layerId,
      entityName
    }
  }));
}