import React, {useCallback} from "react";
import {useParams} from "react-router-dom";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import EntityManagerCleaner from "../../../../components/Entities/EntityManagerCleaner";


const RoadSegmentEdit = () => {
    const entityName = 'replancity_RoadInfrastructureSegment';
    const {roadSegmentId, roadId} = useParams<any>();
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));

    const onSaveFn = useCallback((entity: any) => {
        if (!roadSegmentId) {
            return `../${entity.id}/edit`;
        }
    }, [roadSegmentId]);

    const onDeleteFn = useCallback((entity: any) => '../', []);

    const saveArgsFn = useCallback((entity: any) => ({
        roadInfrastructureRoad: {id: roadId}
    }), [roadId]);

    return (
        <EntityManagerCleaner entityName={entityName}>
            <EntityEditor
                entityId={roadSegmentId!}
                entityName={entityName}
                layerId={sourceId}
                saveFnArgs={saveArgsFn}
                presetProperty={'roadInfrastructurePreset'}
                undoLastActionBtn={true}
                getUrlOnSaveFn={onSaveFn}
                getUrlOnDeleteFn={onDeleteFn}
            />
        </EntityManagerCleaner>
    )
}

export default RoadSegmentEdit;