import React, {useCallback} from "react";
import {useParams} from "react-router-dom";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../redux/selectors/selectors";
import EntityManagerCleaner from "../../../components/Entities/EntityManagerCleaner";
import EntityEditor from "../../../components/Entities/EntityEditor";
import {CHARGER_PROPERTY_ENTITYNAME} from "../../../api/entities/replancity_ChargerProperty";


const ChargerEdit = () => {
    const entityName = CHARGER_PROPERTY_ENTITYNAME;
    const {chargerId} = useParams<any>();
    const stopsLayerId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));

    const onSaveFn = useCallback((entity: any) => {
        if (!chargerId) {
            return `../${entity.id}/edit`;
        }
    }, [chargerId]);

    const onDeleteFn = useCallback((entity: any) => '../', []);

    return (
        <EntityManagerCleaner entityName={entityName}>
            <EntityEditor
                entityId={chargerId!}
                entityName={entityName}
                layerId={stopsLayerId}
                presetProperty={'evPreset'}
                getUrlOnSaveFn={onSaveFn}
                getUrlOnDeleteFn={onDeleteFn}
            />
        </EntityManagerCleaner>
    )
}

export default ChargerEdit;