import React, {useCallback} from "react";
import {useParams} from "react-router-dom";
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import EntityManagerCleaner from "../../../../components/Entities/EntityManagerCleaner";


const TransitLineEdit = () => {
    const entityName = PublicTransitLayerEntityName.LINES;
    const {lineId} = useParams<any>();
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));

    //TODO there was check if lineId exists previously
    const onSaveFn = useCallback((entity: any) => {
        if (lineId) {
            return `../../${entity.id}/edit`;
        }
        return `../${entity.id}/edit`;
    }, []);

    const onDeleteFn = useCallback((entity: any) => `../../`, []);

    //TODO check response view params in saveFn. there was params: {responseView: 'form'},

    return (
        <EntityManagerCleaner entityName={entityName}>
            <EntityEditor
                entityId={lineId!}
                entityName={entityName}
                layerId={sourceId}
                presetProperty={'publicTransitPreset'}
                getUrlOnSaveFn={onSaveFn}
                getUrlOnDeleteFn={onDeleteFn}
            />
        </EntityManagerCleaner>
    )
}

export default TransitLineEdit;