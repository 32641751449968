import React, {useCallback} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectSelectedRunId} from "../../redux/selectors/selectors";
import ExportButton from "../buttons/ExportButton/ExportButton";
import {useTranslation} from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import {ENV} from "../../utils/env";


const ExportMatsimButton = () => {
    const projectId = useTypedSelector(selectCurrentProjectId);
    const selectedRunId = useTypedSelector(selectSelectedRunId);
    const {t} = useTranslation();

    const openLinkInNewTab = useCallback(() => {
        window.open(
            `${ENV.REACT_APP_API_HOST}/${ENV.REACT_APP_API_POINT}projects/${projectId}/exportMatsim?runId=${selectedRunId}`,
            '_blank'
        );
    }, [projectId, selectedRunId]);

    const getButton = (disabled: boolean) => {
        if (disabled) {
            return (
                <Tooltip title={t('simulation.no-runs-to-export')} placement="top">
                  <span>
                      <ExportButton onClick={openLinkInNewTab} disabled={disabled}/>
                  </span>
                </Tooltip>
            );
        }
        return <ExportButton onClick={openLinkInNewTab}/>;
    }

    return (
        <>{getButton(!selectedRunId)}</>
    )
}

export default ExportMatsimButton;