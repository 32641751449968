import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Outlet, useParams} from "react-router-dom";
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";
import {
    PublicTransitLayerEntityName,
    PublicTransitLineEntity,
} from "../../../../api/entities/replancity_PublicTransitLine";
import {
    EditLineIcon,
    EditRouteIcon,
    LineRoutesIcon,
    RouteDepartureEditIcon,
    RouteDeparturesIcon,
    RouteStopsIcon
} from "../../../../components/icons/icons";
import EntityManagerCleaner from "../../../../components/Entities/EntityManagerCleaner";
import IconTabs, {LeftTabs} from "../../../../components/LeftPanelTabs/IconTabs";
import {isUuidString} from "../../../../utils/utils";
import {TabProps} from "@mui/material/Tab/Tab";


const TransitLine = () => {
    const entityName = PublicTransitLayerEntityName.LINES;
    const {loadData} = useEntityLoader<PublicTransitLineEntity>({entityName});

    const {lineId, routeId, departureId} = useParams();
    const existingRouteId = useMemo(() => isUuidString(routeId) ? routeId : '', [routeId]);

    const {t} = useTranslation();

    const tabs: TabProps<any, LeftTabs>[] = [
        {
            path: '',
            label: t('transit-lines.routes'),
            url: './',
            icon: <LineRoutesIcon width={16} height={16}/>,
            testId: 'routesTab'
        },
        {
            path: 'edit',
            label: t('transit-lines.line-edit-form'),
            url: './edit',
            icon: <EditLineIcon width={16} height={16}/>,
            testId: 'editLineTab'
        }
    ];

    if (existingRouteId) {
        tabs.push(
            {
                path: '/',
                label: t('transit-lines.route-stops'),
                url: `./${existingRouteId}/`,
                icon: <RouteStopsIcon width={16} height={16}/>,
                testId: 'routeStopsTab'
            },
            {
                path: 'edit',
                label: t('transit-lines.route-form'),
                url: `./${existingRouteId}/edit`,
                icon: <EditRouteIcon width={16} height={16}/>,
                testId: 'editRouteTab'
            },
            {
                path: 'departures',
                label: t('transit-lines.departures'),
                url: `./${existingRouteId}/departures/`,
                icon: <RouteDeparturesIcon width={16} height={16}/>,
                testId: 'departuresTab'
            },
        )
    }

    if (departureId) {
        tabs.push(
            {
                path: `${departureId}/edit`,
                label: "Departure",
                url: `./${existingRouteId}/departures/${departureId}/edit`,
                icon: <RouteDepartureEditIcon width={20} height={16}/>,
                testId: 'editDepartureTab'
            },
        )
    }


    useEffect(() => {
        (async function () {
            await loadData({entityName, entityId: lineId});
        }());
    }, [loadData, entityName, lineId])


    return (
        <>
            <IconTabs tabs={tabs}/>
            <EntityManagerCleaner entityName={entityName}>
                <Outlet/>
            </EntityManagerCleaner>
        </>
    )
}

export default TransitLine;