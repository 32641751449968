import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectSelectedFeatures,} from "../../../../redux/selectors/selectors";
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";
import {
    PublicTransitLayerEntityName,
    PublicTransitRouteProfileStopEntity
} from "../../../../api/entities/replancity_PublicTransitLine";
import {useDispatch} from "react-redux";
import {isErrorResponse} from "../../../../utils/utils";
import {useToastContext} from "../../../../context/toastContext";
import {clearedSelectedMapFeatures} from "../../../../redux/showInfo/showInfoReducer";
import Button from "../../../../components/buttons/Button";
import {useLayersInteractivity} from "../../../../hooks/map/useLayersInteractivity";


type Props = {
    routeProfileId: string;
    onStopAdditionFn: (reloadLayer?: boolean) => Promise<void>;
}

const AddStopToRoute = memo(({routeProfileId, onStopAdditionFn}: Props) => {
    const entityName = PublicTransitLayerEntityName.STOPS;
    const routeProfileStopEntityName = PublicTransitLayerEntityName.ROUTE_STOPS;
    const [selectionMode, setSelectionMode] = useState<boolean>(false);
    const selectedFeatures = useTypedSelector(selectSelectedFeatures);
    const {
        recoverLayersInteractivity,
        disableLayersInteractivityExceptEntityName,
        toggleRedirectOnFeatureSelection
    } = useLayersInteractivity();
    const {t} = useTranslation();
    const {addToast} = useToastContext();
    const dispatch = useDispatch();
    const abortControllerRef = useRef(new AbortController());

    const {saveFn} = useEntityLoader<PublicTransitRouteProfileStopEntity>({entityName});

    const btnClickHandler = useCallback(() => {
        setSelectionMode(prev => !prev);
    }, [entityName, setSelectionMode])

    const keyPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setSelectionMode(false);
            recoverLayersInteractivity();
            toggleRedirectOnFeatureSelection(true);
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', keyPress);

        return () => {
            document.removeEventListener('keyup', keyPress);
            recoverLayersInteractivity();
            toggleRedirectOnFeatureSelection(true);
        }
    }, []);

    useEffect(() => {
        if (selectionMode) {
            disableLayersInteractivityExceptEntityName(entityName);
            toggleRedirectOnFeatureSelection(false);

            if (selectedFeatures?.length) {
                const feature = selectedFeatures[selectedFeatures.length - 1];
                const {id} = feature;

                if (id) {
                    (async function () {
                        const resp = await saveFn({
                            entityName: routeProfileStopEntityName,
                            id: '',
                            data: {
                                publicTransitRouteProfile: {id: routeProfileId},
                                transitStop: {id}
                            },

                            abortSignal: abortControllerRef.current.signal
                        });

                        dispatch(clearedSelectedMapFeatures());

                        if (!isErrorResponse(resp)) {
                            addToast(`${t('common.save-successful')}`, 'success');
                        }

                        await onStopAdditionFn(true);
                    }());
                }
            }
        } else {
            recoverLayersInteractivity();
            toggleRedirectOnFeatureSelection(true);
        }
    }, [entityName, routeProfileStopEntityName, selectionMode, selectedFeatures, routeProfileId, onStopAdditionFn])

    return (
        <>
            <Button
                text={selectionMode ? t('transit-lines.stop-selection-mode-on') : t('transit-lines.stop-selection-mode-off')}
                onClick={btnClickHandler}
                colorType="transparent"
            />
        </>
    )
})

export default AddStopToRoute;