import {DefaultLayerType} from "../../hooks/map/useDefaultLayers";
import {MapboxLayerType} from "../../api/enums/enums";
import {Option} from "../../components/form/SelectBox/SelectBox";

export const enum TRANSIT_ROUTE_HIGHLIGHTED_LAYER {
    ROUTE = 'transitRouteHighlighted',
    STOPS = 'transitRouteStopsHighlighted'
}

export const NEW_ROUTE_SOURCE: DefaultLayerType[] = [
    {
        id: TRANSIT_ROUTE_HIGHLIGHTED_LAYER.ROUTE,
        type: MapboxLayerType.CLICKEDLINES, //MapboxLayerType.LINESTRING,
        displayName: 'New transit route',
        viewName: 'New transit route',
        entityName: '',
        _entityName: '',
        needAlgorithmId: false,
        showByDefault: true,
        order: -1,
        opacity: 1,
        dayTimeDependent: false,
        clusterize: false,
        clickable: false,
        queryable: false,
        visibleInLayersList: false,
        updateOnFeatureSelection: false,
        group: ''
    },
    {
        id: TRANSIT_ROUTE_HIGHLIGHTED_LAYER.STOPS,
        type: MapboxLayerType.STOPS,
        displayName: 'Highlighted route stops',
        viewName: 'Highlighted route stops',
        entityName: '',
        _entityName: '',
        needAlgorithmId: false,
        showByDefault: true,
        order: -1,
        opacity: 1,
        dayTimeDependent: false,
        clusterize: false,
        clickable: false,
        queryable: false,
        visibleInLayersList: false,
        updateOnFeatureSelection: false,
        group: ''
    }
];

export const CHECKED_ROUTE_SOURCE_NAME = 'checkedRouteSource';

export const CHECKED_ROUTE_SOURCE: DefaultLayerType[] = [
    {
        id: CHECKED_ROUTE_SOURCE_NAME,
        type: MapboxLayerType.LINESTRING,
        displayName: '',
        viewName: '',
        entityName: CHECKED_ROUTE_SOURCE_NAME,
        _entityName: '',
        needAlgorithmId: false,
        showByDefault: true,
        order: 1,
        opacity: 1,
        dayTimeDependent: false,
        clusterize: false,
        clickable: true,
        queryable: false,
        visibleInLayersList: false,
        updateOnFeatureSelection: false,
        group: ''
    }
]

export const copyModeOptions: Option[] = [
    {
        caption: 'Full copy',
        value: 'FULL_COPY'
    },
    // {
    //     caption: 'Only stops',
    //     value: 'ONLY_STOPS'
    // }
];