import {useDispatch} from "react-redux";
import {useCallback, useMemo} from "react";
import {addedSourceData} from "../../redux/map/map-reducer";
import {featureToFeatureCollection} from "../../utils/mapUtils";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectBorders} from "../../redux/selectors/selectors";
import {EntityMapLayer} from "../../api/entities/replancity_MapLayer";
import {MapboxLayerType, EntityServiceName} from "../../api/enums/enums";


export type DefaultLayerType = EntityMapLayer &
    {
        queryable?: boolean;
        visibleInLayersList?: boolean;
    };

const PROJECT_BORDERS_SOURCE: DefaultLayerType = {
    id: 'projectBorders',
    type: MapboxLayerType.POLYGON,
    displayName: 'Project Borders',
    viewName: 'Project Borders',
    entityName: '',
    _entityName: '',
    needAlgorithmId: false,
    showByDefault: true,
    order: -1,
    opacity: 0.1,
    dayTimeDependent: false,
    clusterize: false,
    clickable: false,
    queryable: false,
    visibleInLayersList: true,
    updateOnFeatureSelection: false,
    group: ''
  };

const DRAWN_RECTANGLE_SOURCE: DefaultLayerType = {
    id: 'drawnRectangleSource',
    type: MapboxLayerType.POLYGON,
    displayName: 'Project Borders',
    viewName: 'Project Borders',
    entityName: '',
    _entityName: '',
    needAlgorithmId: false,
    showByDefault: true,
    order: -1,
    opacity: 0.3,
    dayTimeDependent: false,
    clusterize: false,
    clickable: false,
    queryable: false,
    visibleInLayersList: false,
    updateOnFeatureSelection: false,
    group: ''
  };

export const useDefaultLayers = (mapConfigType: EntityServiceName, defaultSources?: DefaultLayerType[]) => {
  const currentProjectBordersData = useTypedSelector(selectCurrentProjectBorders);
  const dispatch = useDispatch();

    const sources: DefaultLayerType[] = useMemo(() => {
        const sources = [...(defaultSources ?? [])];
        if (mapConfigType === EntityServiceName.MAP_CONFIG) {
            sources.push(PROJECT_BORDERS_SOURCE);
        }
        sources.push(DRAWN_RECTANGLE_SOURCE);

        return sources;
    }, [])

  const addProjectBordersLayerData = useCallback(() => {
    if (currentProjectBordersData) {
      const parsedData = featureToFeatureCollection(JSON.parse(currentProjectBordersData));

      dispatch(addedSourceData({
        sourceId: 'projectBorders',
        data: parsedData
      }));
    }
  }, [currentProjectBordersData])

  return {defaultLayersArr: sources, addProjectBordersLayerData};
}