import React, {Dispatch, memo, SetStateAction, useCallback} from "react";
import './edit-geometry-toolbar.scss';
import {useTheme} from "../../../context/themeContext";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectDrawMode} from "../../../redux/selectors/selectors";
import Button from "../../buttons/Button";
import {DrawControlsClass} from "../../../utils/DrawControlsClass";
import {useLayersInteractivity} from "../../../hooks/map/useLayersInteractivity";
import {useToastContext} from "../../../context/toastContext";
import {FeatureProperties} from "../../../redux/map/types";


type Props = {
    children?: React.ReactElement | null;
    drawnFeatures: GeoJSON.Feature<any, Partial<FeatureProperties>>[];
    setFeature: Dispatch<SetStateAction<string>>;
};

const EditGeometryToolbar: React.FC<Props> = ({children, drawnFeatures, setFeature}) => {
    const drawMode = useTypedSelector(selectDrawMode);
    const {cleanDrawnFeatures} = useLayersInteractivity();
    const {recoverSettingsAfterDrawing} = useLayersInteractivity();
    const {theme} = useTheme();
    const {t} = useTranslation();
    const {addToast} = useToastContext();

    const modeString = `Edit mode: ${drawMode}`;

    const applyChangesClickHandler = useCallback(async () => {
        const feature = drawnFeatures?.[0] ? JSON.stringify(drawnFeatures?.[0]) : '';
        setFeature(feature);

        DrawControlsClass.setDrawControlVisibility({drawType: 'AllControls', visible: false});
        addToast(t('forms.geometry-updated-toast'), 'success');
    }, [drawnFeatures]);

    const discardGeometryChanges = useCallback(() => {
        cleanDrawnFeatures();
        recoverSettingsAfterDrawing();

        setFeature('');
    }, []);

    return (
        <div
            className={`edit-geometry-toolbar ${theme}`}
            data-testid="editGeometryToolbar"
        >
            {children}
            {modeString}
            <Button
                text={t('map.apply-changes-btn')}
                onClick={applyChangesClickHandler}
                // disabled={drawnFeatures?.length}
                colorType="transparent"
                testId="applyGeometryUpdateBtn"
            />
            <Button
                text={t('map.discard-geometry-change')}
                onClick={discardGeometryChanges}
                disabled={!drawnFeatures?.length}
                colorType="transparent"
                testId="cancelGeometryUpdateBtn"
            />
        </div>
    )
}

export default memo(EditGeometryToolbar);