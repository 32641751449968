import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import SidePanel from "../sidePanel/SidePanel";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectSelectedFeatures} from "../../redux/selectors/selectors";
import SidePanelDashboard from "../../containers/SidePanelDashboard/SidePanelDashboard";
import {LinkInfoDashboards} from "../LinkInfoDashboards/LinkInfoDashboards";
import {useTranslation} from "react-i18next";
import {InfoIcon} from "../icons/icons/InfoIcon";
import {IconButton} from "../buttons/IconButton/IconButton";


const SidePanelManager = () => {
    const [showSidePanel, setSidePanelFn] = useState<boolean>(false);
    const [selectedSidePanelTabInd, setSelectedTabInd] = useState(0);
    const selectedFeatures = useTypedSelector(selectSelectedFeatures);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const sidePanelTabs: any[] = [
        {
            label: t('map.side-panel-dashboard-ss-title'),
            content: <SidePanelDashboard type={'SUPERSET'}/>
        },
        {
            label: t('map.side-panel-title'),
            content: <LinkInfoDashboards/>
        }
    ];

    useEffect(() => {
        if (selectedFeatures?.length) {
            setSidePanelFn?.(true);
            //TODO remove hardcoded index
            setSelectedTabInd(1);
        }
    }, [selectedFeatures, setSidePanelFn, dispatch])

    return (
        <SidePanel
            open={showSidePanel}
            setOpened={setSidePanelFn}
            tabs={sidePanelTabs}
            selectedTabInd={selectedSidePanelTabInd}
            sideToolbars={
                <IconButton
                    icon={InfoIcon}
                    isActive={showSidePanel}
                    onClick={setSidePanelFn}
                />
            }
        />
    )
}

export default SidePanelManager;