import React from "react";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import ProjectPresetsSelector from "../../components/ProjectPresetsSelector/ProjectPresetsSelector";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectPresetType, selectSelectedPresetId} from "../../redux/selectors/selectors";
import {useTranslation} from "react-i18next";
import MapContainerWithSources from "../../containers/map/MapContainer/MapContainerWithSources";
import {EntityServiceName} from "../../api/enums/enums";
import {useNavigationOnFeaturesSelection} from "../../hooks/transitlLines/useNavigationOnFeaturesSelection";
import RoadNetworkManager from "./RoadNetworkManager/RoadNetworkManager";
import {CHECKED_ROUTE_SOURCE} from "../PublicTransitMode/constants";
import RoadRouteCheckerMarkers from "../../containers/map/RoadRouteCheckerMarkers/RoadRouteCheckerMarkers";


const getUrlFunc = ({parentId, childId}: {
    parentId?: string | number;
    childId: string | number;
}) => `./${parentId}/${childId}/edit`;

const RoadNetworkEditorModePage = () => {
    const presetEntityName = 'replancity_RoadInfrastructurePreset';
    const presetId = useTypedSelector(selectSelectedPresetId);
    const currentPresetType = useTypedSelector(selectPresetType);
    const {navigateOnFeatureSelection} = useNavigationOnFeaturesSelection({getUrlFunc, findParent: true});
    const {t} = useTranslation();

    return (
        <ProjectPage
            showSkeleton={!presetId || presetEntityName !== currentPresetType}
            skeletonExplanation={t('common.presets-absence-error-toast')}
            subHeaderLeft={
                <ProjectPresetsSelector
                    presetEntityName={presetEntityName}
                    serviceName={EntityServiceName.ROAD_INFRASTRUCTURE}
                    copySpinnerMsg={t('road-network.preset-copy-spinner-msg')}
                    testId="clonePresetBtn"
                />}
            main={
                <MapContainerWithSources
                    mapConfigType={EntityServiceName.ROAD_INFRASTRUCTURE}
                    isDrawerEnabled={true}
                    handleMapClick={navigateOnFeatureSelection}
                    defaultSources={CHECKED_ROUTE_SOURCE}
                >
                    <RoadRouteCheckerMarkers/>
                </MapContainerWithSources>
            }
            leftPanel={<RoadNetworkManager/>}
            leftPanelTitle={t('road-network.road-editor')}
        />
    )
}

export default RoadNetworkEditorModePage;