import {z} from 'zod';

const envSchema = z.object({
    REACT_APP_PLANSIM_URL: z.string().url(),
    REACT_APP_API_HOST: z.string().url(),
    REACT_APP_API_POINT: z.string(),
    REACT_APP_SSE_POINT: z.string(),
    REACT_APP_API_CLIENT_ID: z.string(),
    REACT_APP_API_CLIENT_SECRET: z.string(),
    REACT_APP_MAPBOX_ACCESS_TOKEN: z.string(),
    REACT_APP_REPLAN_SALES_EMAIL: z.string().email(),
});

export const ENV = envSchema.parse(process.env);