import {Link} from "react-router-dom";
import {RoadInfrastructureSegmentIdPairEntity} from "../../../../api/entities/replancity_RoadInfrastructureRoute";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";


type Props = { routeId: string; } & RoadInfrastructureSegmentIdPairEntity;

const RoadSegmentCard = ({routeId, id, length, capacity, freeSpeed}: Props) => {
    const {t} = useTranslation();

    return (
        <Tooltip title={t('route-check.road-segment-card-tooltip')} placement="right">
            <Link to={`../${routeId}/${id}/edit`}>
                <div className="segment-card">
                    <div className="segment-card__line">Road segment: {id}</div>
                    <div className="segment-card__line segment-card__properties">
                        <span>Length: {length},</span>
                        <span>Capacity: {capacity},</span>
                        <span>Free speed: {freeSpeed}</span>
                    </div>
                </div>
            </Link>
        </Tooltip>
    )
}

export default RoadSegmentCard;