import React, {useCallback} from "react";
import {useParams} from "react-router-dom";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../redux/selectors/selectors";
import EntityEditor from "../../../components/Entities/EntityEditor";
import {DrtZoneEntity} from "../../../api/entities/replancity_DrtZone";
import EntityManagerCleaner from "../../../components/Entities/EntityManagerCleaner";


const MaasZoneEdit = () => {
    const entityName = 'replancity_DrtZone';
    const {zoneId} = useParams<any>();
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));

    const onSaveFn = useCallback((entity: any) => {
        if (!zoneId) {
            return `../${entity.id}/edit`;
        }
    }, [zoneId]);

    //TODO redirect to entities list if opend entity doesn't exist (was deleted)

    return (
        <EntityManagerCleaner entityName={entityName}>
            <EntityEditor<DrtZoneEntity>
                entityId={zoneId!}
                entityName={entityName}
                layerId={sourceId}
                presetProperty={'maasPreset'}
                getUrlOnSaveFn={onSaveFn}
            />
        </EntityManagerCleaner>
    )
}

export default MaasZoneEdit;