import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Form, Row} from "../../../../components/form/Form/Form";
import {MapMarkerField} from "../../../../components/form/MapMarkerField/MapMarkerField";
import {getFilledFieldsObjOnFormSubmit, isErrorResponse} from "../../../../utils/utils";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../../../context/themeContext";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {
    selectCurrentProjectCenter,
    selectMapLayerIdByEntityName,
    selectSelectedPresetId
} from "../../../../redux/selectors/selectors";
import {PointCoordinates} from "../../../../components/form/MapMarkerField/MapMarkerField";
import {roadManagementApi} from "../../../../api/roadManagementApi";
import * as SelectBox from "../../../../components/form/SelectBox/SelectBox";
import {Option} from "../../../../components/form/SelectBox/SelectBox";
import {LoadingBackdrop} from "../../../../components/LoadingBackdrop/LoadingBackdrop";
import {clearSourceData, requireToHighlightFeatures} from "../../../../redux/map/map-reducer";
import {CHECKED_ROUTE_SOURCE_NAME} from "../../../PublicTransitMode/constants";
import {cleanRoadRouteCheckPoints} from "../../../../redux/road-route-check/road-route-check-reducer";
import './road-route-checker.scss';
import {
    RoadInfrastructureRouteEntity,
} from "../../../../api/entities/replancity_RoadInfrastructureRoute";
import RoadSegmentCard from "./RoadSegmentCard";


export const SECOND_MARKER_OFFSET = 0.008

const RoadRouteChecker = () => {
    const entityName = 'replancity_RoadInfrastructureSegment';
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const [loading, setLoading] = useState<boolean>(false);
    const [routeDetails, setRouteDetails] = useState<RoadInfrastructureRouteEntity>({
        id: '',
        distance: 0,
        segments: [],
        straightDistance: 0,
        time: 0
    });
    const [modes, setModes] = useState<Option[]>([]);
    const formRef = useRef<HTMLFormElement>(null);
    const projectCenterStr = useTypedSelector(selectCurrentProjectCenter);
    const presetId = useTypedSelector(selectSelectedPresetId);
    const dispatch = useDispatch();
    const {theme} = useTheme();
    const {t} = useTranslation();

    const projectCenterCoords: PointCoordinates = useMemo(() => {
        const projectCenterCoordsArr = projectCenterStr.split(',');
        return {lat: +projectCenterCoordsArr[0], lng: +projectCenterCoordsArr[1]};
    }, [projectCenterStr])

    useEffect(() => {
        return () => {
            dispatch(cleanRoadRouteCheckPoints());
            dispatch(clearSourceData(CHECKED_ROUTE_SOURCE_NAME));
        }
    }, []);

    useEffect(() => {
        if (presetId) {
            const abortController = new AbortController();
            (async function () {
                setLoading(true);

                const resp = await roadManagementApi.getModes({presetId}, abortController.signal);
                if (resp && Array.isArray(resp)) {
                    // if (!isErrorResponse(resp)) {
                    setModes(resp.map(mode => ({
                        value: mode,
                    })));
                } else {
                    console.error('Replan. listAllModes response doesn\'t include array of modes');
                }

                setLoading(false);
            }());
        }
    }, [presetId]);

    const onFormSubmit = useCallback(async (event) => {
        const abortController = new AbortController();

        const formDataObj = getFilledFieldsObjOnFormSubmit(event);

        const fromPoint = JSON.parse(formDataObj.fromPoint as string);
        const toPoint = JSON.parse(formDataObj.toPoint as string);

        setLoading(true);

        const resp = await roadManagementApi.calculateAndGetRouteGeometry({
            presetId,
            fromLon: fromPoint.lng,
            fromLat: fromPoint.lat,
            toLon: toPoint.lng,
            toLat: toPoint.lat,
            mode: formDataObj.mode as string
        }, abortController.signal);

        if (!isErrorResponse(resp)) {
            const {distance, id, segments, straightDistance, time} = resp;

            setRouteDetails({distance, id, segments, straightDistance, time});
            dispatch(requireToHighlightFeatures({
                featureProperties: segments.map(({id, featureStateId, _entityName}) => ({
                    id,
                    featureStateId,
                    layerId: sourceId,
                    entityName: _entityName
                }))
            }));
        }

        setLoading(false);

        return;
    }, [projectCenterCoords, entityName, presetId, sourceId])

    return (
        <div className="road-route-checker">
            <div className="road-route-checker__form">
                <div className="road-route-checker__header">

                </div>
                <LoadingBackdrop isVisible={loading} transparent>
                    <div className="entity-editor__header">
                        <div className="title">
                        </div>
                    </div>
                    <Form
                        name="entityForm"
                        onFormSubmit={onFormSubmit}
                        submitBtnTitle={t('route-check.form-submit-btn')}
                        ref={formRef}
                        submitBtnTestId="entityFormSubmitBtn"
                        testId="entityForm"
                    >
                        <Row>
                            <MapMarkerField
                                name="fromPoint"
                                defaultCoordinates={projectCenterCoords}
                                fieldGroupClassName="map-marker-form-field"
                            />
                            <MapMarkerField
                                name="toPoint"
                                defaultCoordinates={{
                                    lng: projectCenterCoords.lng + SECOND_MARKER_OFFSET,
                                    lat: projectCenterCoords.lat + SECOND_MARKER_OFFSET
                                }} // hardcoded also in RoadNetworkEditorModePage
                                fieldGroupClassName="map-marker-form-field"
                            />
                        </Row>
                        <Row>
                            <SelectBox.Labeled
                                label={t('route-check.form-transport-mode')}
                                name="mode"
                                options={modes}
                            />
                        </Row>
                    </Form>
                </LoadingBackdrop>
            </div>
            <div className="road-route-checker__result">
                {
                    routeDetails.segments.length
                        ? <>
                            <div className="road-route-checker__distances">
                                Distance: {routeDetails.distance}, straight distance: {routeDetails.straightDistance},
                                time: {routeDetails.time}
                            </div>
                            <div className="road-route-checker__segments">
                                {
                                    routeDetails.segments.map(segment => (
                                        <RoadSegmentCard
                                            key={segment.id}
                                            routeId={routeDetails.id}
                                            {...segment}
                                        />
                                    ))
                                }
                            </div>
                        </>
                        : null
                }
            </div>
        </div>
    )
}

export default RoadRouteChecker;