import React, {useRef} from "react";
import {FieldWithLabel} from "../../../hoc/withClass";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectRoadRouteCheckerPoint,} from "../../../redux/selectors/selectors";
import {useTranslation} from "react-i18next";
import '../GeometryField/geometry-form-field.scss';
import classNames from "classnames";
import {useTheme} from "../../../context/themeContext";
import {FormFieldProps} from "../Form/Form";

export type PointCoordinates = {
    lng: number;
    lat: number;
}

type Props = {
    label?: string;
    name: string;
    defaultCoordinates: PointCoordinates;
} & FormFieldProps;

const MapMarkerField = ({name, defaultCoordinates}: Props) => {
    const ref = useRef<any>(null);
    const point = useTypedSelector((state) => selectRoadRouteCheckerPoint(state, name));
    const {theme} = useTheme();
    const {t} = useTranslation();

    const className = classNames(
        'text-field-md',
        theme
    );

    return (
        <div className="geometry-form-field" style={{flexGrow: 0}}>
            <input
                name={name}
                type="text"
                value={JSON.stringify(point ?? defaultCoordinates)}
                hidden
                readOnly
                ref={ref}
            />
        </div>
    )
}

const Labeled = FieldWithLabel<Props>(MapMarkerField);

export {Labeled, MapMarkerField}