import {DrawModeWithRectangle} from "../../components/map/Drawer/types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export type DrawStoreState = {
    isDrawMode: boolean;
    editingEnabled: boolean;
    drawMode: DrawModeWithRectangle;
    snapMode: boolean;
    cutMode: boolean;
    drawnFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] | null
}

const initialState: DrawStoreState = {
    isDrawMode: false,
    editingEnabled: false,
    drawMode: 'simple_select',
    snapMode: false,
    cutMode: false,
    drawnFeatures: []
}

const drawSlice = createSlice({
    name: 'draw',
    initialState,
    reducers: {
        setIsDrawMode(state, action: PayloadAction<boolean>) {
            state.isDrawMode = action.payload;
        },
        enableEditing(state, action: PayloadAction<boolean>) {
            state.editingEnabled = action.payload;
        },
        setDrawMode(state, action: PayloadAction<DrawModeWithRectangle>) {
            state.drawMode = action.payload;
        },
        setSnapMode(state, action: PayloadAction<boolean>) {
            state.snapMode = action.payload;
        },
        setCutMode(state, action: PayloadAction<boolean>) {
            state.cutMode = action.payload;
        },
        setDrawnFeatures(state, action: PayloadAction<GeoJSON.Feature<GeoJSON.Geometry>[] | undefined>) {
            state.drawnFeatures = action.payload ?? [];
        },
        setDrawerToDefault(state) {
            return {
                ...state,
                isDrawMode: false,
                snapMode: false,
                cutMode: false,
                drawnFeatures: []
            }
        }
    },
});

export const {
    setIsDrawMode,
    enableEditing,
    setDrawMode,
    setSnapMode,
    setCutMode,
    setDrawnFeatures,
    setDrawerToDefault
} = drawSlice.actions;

export default drawSlice.reducer
