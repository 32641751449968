import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {debounce} from "lodash";
import {IconButton} from "../../buttons/IconButton/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {SimulationState} from "../../../api/entities/replancity_RunnedAlgorithm";
import {projectsApi} from "../../../api/projectsApi";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectSelectedRunId} from "../../../redux/selectors/selectors";
import {InfoIcon} from "../../icons/icons/InfoIcon";
import Modal from "../../Modal/Modal";
import {isErrorResponse} from "../../../utils/utils";
import {themeColors} from "../../../styles/theme";
import {useTheme} from "../../../context/themeContext";
import * as TextField from "../../form/TextField/TextField";
import './simulation-log.scss';
import {LoadingBackdrop} from "../../LoadingBackdrop/LoadingBackdrop";
import Timer from "../../Timer/Timer";


const LOG_REQUEST_TIMEOUT = 30000;

const SimulationLog = ({simulationState}: { simulationState: SimulationState }) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [log, setLog] = useState<string[]>([]);
    const [query, setQuery] = useState<string>('');
    const selectedRunId = useTypedSelector(selectSelectedRunId);
    const ref = useRef(null);
    const intervalRef = useRef<NodeJS.Timer>();
    const {theme} = useTheme();
    const {t} = useTranslation();

    let lineIndex = -1;
    const regex = new RegExp(query, "i");
    const filteredLog = log.filter(line => regex.test(line)).map((line) => <p key={lineIndex++}>{line}</p>);

    useEffect(() => {
        return () => {
            setQuery('');
        }
    }, []);

    useEffect(() => {
        if (!modalVisible && intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        return () => {
            clearInterval(intervalRef.current);
        }
    }, [modalVisible]);

    useEffect(() => {
        let shouldScroll = true;
        if (ref.current && shouldScroll) {
            const logElem: any = ref.current;
            logElem.scrollIntoView({behavior: "instant", block: "end"});
            shouldScroll = false;
        }
    }, [filteredLog]);

    const loadData = useCallback(async (runId: string) => {
        setLoading(true);

        const resp = await projectsApi.getSimulationLogs({runId});
        if (!isErrorResponse(resp)) {
            setLog((resp.split('\n')));
        }

        setLoading(false);
    }, [])

    const modalHandler = useCallback(async () => {
        setQuery('');
        setModalVisible(true);

        await loadData(selectedRunId);
        intervalRef.current = setInterval(() => loadData(selectedRunId), LOG_REQUEST_TIMEOUT);
    }, [selectedRunId])

    const searchHandler = debounce(useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []), 500)

    return (
        <>
            <Tooltip title={'Show simulation log'}>
                <IconButton
                    icon={InfoIcon}
                    width={20}
                    height={20}
                    onClick={modalHandler}
                    borderless
                    disabled={simulationState === SimulationState.NEVER}
                />
            </Tooltip>
            {modalVisible && createPortal(
                <Modal
                    title={t('simulation.simulation-log-modal-title')}
                    setVisible={setModalVisible}
                    style={{
                        width: '80%',
                        height: '100%',
                        color: theme === 'light' ? themeColors.navyBlue : themeColors.white
                    }}
                >
                    <LoadingBackdrop isVisible={loading}>
                        <div className="log">
                            <Timer milliSeconds={LOG_REQUEST_TIMEOUT} message={t('simulation.simulation-log-timer')}/>
                            <div className="log__controls">
                                <TextField.Labeled
                                    label={t('simulation.simulation-log-modal-search')}
                                    name="logSearch"
                                    type="text"
                                    value={query}
                                    onChange={searchHandler}
                                    autoFocus
                                    // testId=""
                                />
                            </div>
                            <div className="log__data">
                                <div ref={ref}>
                                    {
                                        filteredLog
                                    }
                                </div>
                            </div>
                        </div>
                    </LoadingBackdrop>
                </Modal>,
                document.getElementById('root') as any)
            }
        </>
    );
}

export default memo(SimulationLog);