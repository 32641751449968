import React, {useCallback} from "react";
import {useParams} from "react-router-dom";
import {
    PublicTransitLayerEntityName,
} from "../../../../api/entities/replancity_PublicTransitLine";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import {isUuidString} from "../../../../utils/utils";


const TransitRouteEdit = () => {
    const entityName = PublicTransitLayerEntityName.ROUTES;
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const {lineId, routeId} = useParams();

    const onSaveFn = useCallback((entity: any) => `../${isUuidString(routeId) ? '../' : ''}${entity.id}/edit`, [routeId]);

    const onDeleteFn = useCallback(() => `../../`, []);

    const saveArgsFn = useCallback((entity: any) => ({
        publicTransitLine: {
            id: lineId
        },
        defaultVehicleType: {id: entity.defaultVehicleType}
    }), [lineId]);

    //TODO remove loadData in EntityEditor for Route. entityView prop is a hack here to eliminate 2nd load in EntityEditor => route entity without "profile" data saved in redux
    // loadByDefault is to avoid entity load in TransitRouteEdit => update architecture
    return (
        <EntityEditor
            entityId={routeId!}
            entityName={entityName}
            entityView="full"
            layerId={sourceId}
            saveFnArgs={saveArgsFn}
            getUrlOnSaveFn={onSaveFn}
            getUrlOnDeleteFn={onDeleteFn}
            loadByDefault={!routeId}
        />
    )
}

export default TransitRouteEdit;