import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {Marker, MarkerDragEvent} from "react-map-gl";
import Pin from "../../../components/icons/icons/Pin";
import {
    SECOND_MARKER_OFFSET
} from "../../../project-modes/RoadNetworkMode/RoadNetworkManager/RoadRouteChecker/RoadRouteChecker";
import {PointCoordinates} from "../../../components/form/MapMarkerField/MapMarkerField";
import {setRoadRouteCheckPoint} from "../../../redux/road-route-check/road-route-check-reducer";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectCenter} from "../../../redux/selectors/selectors";


const RoadRouteCheckerMarkers = () => {
    const [showMarkers, setShowMarkers] = useState<boolean>(false);
    const projectCenterStr = useTypedSelector(selectCurrentProjectCenter);
    const dispatch = useDispatch();
    const {pathname} = useLocation();

    //TODO replace check of hardcoded URL path
    useEffect(() => {
        setShowMarkers(pathname.includes('/checkroute'));
    }, [pathname]);

    const projectCenterCoordsArr = projectCenterStr.split(',');
    const projectCenterCoords: PointCoordinates = {lat: +projectCenterCoordsArr[0], lng: +projectCenterCoordsArr[1]};

    const onMarkerDragEnd = useCallback((event: MarkerDragEvent) => {
        const {target, lngLat} = event;

        const markerElementId = target.getElement().firstElementChild?.id;
        if (markerElementId) {
            dispatch(setRoadRouteCheckPoint({[markerElementId]: {...lngLat}}));
        }
    }, []);

    return (
        <>
            {
                showMarkers
                    ? <>
                        <Marker
                            latitude={projectCenterCoords.lat}
                            longitude={projectCenterCoords.lng}
                            anchor="bottom"
                            draggable
                            onDragEnd={onMarkerDragEnd}
                        >
                            <Pin
                                size={30}
                                elementId="fromPoint"
                            />
                        </Marker>
                        <Marker
                            latitude={projectCenterCoords.lat + SECOND_MARKER_OFFSET} // hardcoded also in RoadRouteChecker
                            longitude={projectCenterCoords.lng + SECOND_MARKER_OFFSET} // hardcoded also in RoadRouteChecker
                            anchor="bottom"
                            draggable
                            onDragEnd={onMarkerDragEnd}
                        >
                            <Pin
                                size={30}
                                color="#CB4337FF"
                                elementId="toPoint"
                            />
                        </Marker>
                    </>
                    : null
            }

        </>
    )
}

export default React.memo(RoadRouteCheckerMarkers);