import {useEffect, useRef} from "react";
import {DateType} from "../../redux/map/types";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectCurrentDateType,
    selectCurrentProjectId,
    selectIfSourceReloadRequired,
    selectLayerById,
    selectLayerCalculationState,
    selectRunIdToCompareWith,
    selectSelectedFeaturesForResponsiveLayer,
    selectSelectedRunId,
    selectSourceDataById,
    selectViewPortCoordinates
} from "../../redux/selectors/selectors";
import mapApi from "../../api/mapApi";
import {MAP_LAYER_BORDER_COORDINATES} from "../../utils/constants";
import {getSecondsFromTimeStr, isErrorResponse} from "../../utils/utils";
import {setLayerLoading, setSourceReloadRequired, addedSourceData} from "../../redux/map/map-reducer";
import {useDispatch} from "react-redux";
import {GeoJSON} from "geojson";
import {isLayerVisibleOnMap, layerHasDontShowState} from "../../utils/mapUtils";
import {ViewPortCoordinates} from "../../api/entities/local/Borders";
import {MapLayerDataType} from "../../api/entities/replancity_MapLayer";
import {SimulationState} from "../../api/entities/replancity_RunnedAlgorithm";
import {DeckLayerType, MapboxLayerType} from "../../api/enums/enums";
import {SelectedFeatureProperties} from "../../redux/showInfo/types";


type Props = {
    sourceId: string;
    type: MapboxLayerType | DeckLayerType;
    layerQueryable?: boolean;
    dayTimeDependent?: boolean;
    zoomDependent?: boolean;
};

type LoaderType = {
    sourceData: GeoJSON.FeatureCollection;
}

const featuresToMapElements = (features: SelectedFeatureProperties[]) => {
    return {
        elements: features.map(({id, entityName}) => {
            return {entityName, featureId: id};
        })
    }
}

export async function loadLayerData({
                                        projectId,
                                        sourceId,
                                        coordinates = {
                                            minLat: MAP_LAYER_BORDER_COORDINATES.bottom,
                                            maxLat: MAP_LAYER_BORDER_COORDINATES.top,
                                            minLon: MAP_LAYER_BORDER_COORDINATES.left,
                                            maxLon: MAP_LAYER_BORDER_COORDINATES.right,
                                            zoom: 13
                                        },
                                        dateType = {name: '', fromTime: '', toTime: ''},
                                        selectedRunId,
                                        runIdToCompareWith,
                                        selectedFeatures,
                                        lngLat = {lng: null, lat: null},
                                        zoomDependent,
                                        abortSignal
                                    }: {
    projectId: string,
    sourceId: string,
    coordinates?: ViewPortCoordinates,
    dateType?: DateType,
    selectedRunId?: string,
    runIdToCompareWith?: string,
    selectedFeatures?: SelectedFeatureProperties[] | null,
    lngLat?: {
        lng: number | null;
        lat: number | null;
    },
    zoomDependent: boolean;
    abortSignal: AbortSignal
}): Promise<MapLayerDataType> {
    const {fromTime, toTime} = dateType;
    const {lng, lat} = lngLat;
    return await mapApi.fetchAnyLayerData(projectId, {
            layerId: sourceId,
            ...coordinates,
            ...(fromTime ? {fromTime: getSecondsFromTimeStr(fromTime)} : {}),
            ...(toTime ? {toTime: getSecondsFromTimeStr(toTime)} : {}),
            ...(selectedRunId ? {runId: selectedRunId} : {}),
            ...(runIdToCompareWith ? {runToCompareUuid: runIdToCompareWith} : {}),
            ...(selectedFeatures?.length ? {
                selectedMapElementsRequest: {
                    ...featuresToMapElements(selectedFeatures),
                    ...(lng && lat ? {lat, lon: lng} : {})
                }
            } : {}),
            zoomDependent,
        },
        abortSignal
    );
}

export const useLayerDataLoader = ({
                                       sourceId,
                                       type,
                                       layerQueryable = true,
                                       dayTimeDependent = false,
                                       zoomDependent = false
                                   }: Props): LoaderType => {
    const projectId = useTypedSelector(selectCurrentProjectId);
    const sourceData: GeoJSON.FeatureCollection = useTypedSelector(state => selectSourceDataById(state, sourceId));
    // const [sourceData, setSourceData] = useState<any>();
    const layerCalculationState: SimulationState = useTypedSelector(state => selectLayerCalculationState(state, sourceId));
    const coordinates = useTypedSelector<ViewPortCoordinates>(selectViewPortCoordinates);
    const isSourceReloadRequired = useTypedSelector(state => selectIfSourceReloadRequired(state, sourceId));
    const {
        features: selectedFeatures,
        lngLat
    } = useTypedSelector((state) => selectSelectedFeaturesForResponsiveLayer(state, sourceId)) ?? {};
    const layer = useTypedSelector(state => selectLayerById(state, sourceId));
    const currentDateType: DateType = useTypedSelector(selectCurrentDateType);
    const selectedRunId = useTypedSelector(selectSelectedRunId);
    const runIdToCompareWith = useTypedSelector(selectRunIdToCompareWith);
    const layerLoaded = useRef<boolean>(false);
    const coordinatesRef = useRef<ViewPortCoordinates>(coordinates);
    const dateTimeRef = useRef<DateType>(currentDateType);
    const dispatch = useDispatch();

    const layerVisible = isLayerVisibleOnMap(layer);
    const {layerName} = layer ?? {};

    const isLayerAllowedToLoad = layerVisible && !layerHasDontShowState(layerCalculationState) && layerQueryable;

    useEffect(() => {
        const abortController = new AbortController();

        let shouldLoad = false;
        if (!layerLoaded.current) {
            shouldLoad = isLayerAllowedToLoad;
        }
        if (coordinatesRef.current !== coordinates && zoomDependent) {
            shouldLoad = isLayerAllowedToLoad;
        }
        if (dateTimeRef.current !== currentDateType && dayTimeDependent) {
            shouldLoad = isLayerAllowedToLoad;
        }
        if (isSourceReloadRequired) {
            shouldLoad = isLayerAllowedToLoad;
        }
        // for "spider" layers where features loaded on links click
        if (selectedFeatures?.length) {
            shouldLoad = true;
        }

        if (shouldLoad) {
            (async function () {
                dispatch(setLayerLoading({layerId: sourceId, loading: true}));

                const resp: MapLayerDataType = await loadLayerData({
                    projectId,
                    sourceId,
                    coordinates,
                    dateType: currentDateType,
                    selectedRunId,
                    runIdToCompareWith,
                    selectedFeatures,
                    lngLat,
                    zoomDependent,
                    abortSignal: abortController.signal
                });

                if (resp) {
                    if (!isErrorResponse(resp)) {
                        const {distribution, featureCollection, legend} = resp;
                        const {type, features} = featureCollection ?? {type: 'FeatureCollection', features: []};
                        dispatch(addedSourceData({
                            sourceId,
                            data: {type, features},
                            ...(legend ? {legend} : {}),
                            ...(distribution ? {distribution: {...distribution, layerName}} : {})
                        }));
                        // setSourceData(featureCollection);
                    }

                    // required to keep spinner to be visible after request cancellation
                    if (!abortController.signal.aborted) {
                        dispatch(setSourceReloadRequired({sourceId, isReloadRequired: false}));
                        dispatch(setLayerLoading({layerId: sourceId, loading: false}));
                    }

                    if (!abortController.signal.aborted) {
                        layerLoaded.current = true;
                    }
                }

            })();

            coordinatesRef.current = coordinates;
            dateTimeRef.current = currentDateType;
        }

        return () => {
            abortController.abort();
        }
    }, [
        isLayerAllowedToLoad,
        currentDateType,
        selectedRunId,
        runIdToCompareWith,
        coordinates,
        layerVisible,
        layerCalculationState,
        layerName,
        projectId,
        sourceId,
        type,
        dispatch,
        layerQueryable,
        selectedFeatures,
        lngLat,
        isSourceReloadRequired,
        zoomDependent
    ])

    return {sourceData};
}