import React, {useCallback} from "react";
import {useParams} from "react-router-dom";
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import EntityManagerCleaner from "../../../../components/Entities/EntityManagerCleaner";


const StopEdit = () => {
    const entityName = PublicTransitLayerEntityName.STOPS;
    const {stopId} = useParams<any>();
    const stopsLayerId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, PublicTransitLayerEntityName.STOPS));

    //TODO fix "fly to" and stop highlighting from useTransitStopLoader

    const onSaveFn = useCallback((entity: any) => {
        if (!stopId) {
            return `../${entity.id}/edit`;
        }
    }, [stopId]);

    const onDeleteFn = useCallback((entity: any) => '../', []);

    return (
        <EntityManagerCleaner entityName={entityName}>
            <EntityEditor
                entityId={stopId!}
                entityName={entityName}
                layerId={stopsLayerId}
                presetProperty={'publicTransitPreset'}
                getUrlOnSaveFn={onSaveFn}
                getUrlOnDeleteFn={onDeleteFn}
            />
        </EntityManagerCleaner>
    )
}

export default StopEdit;