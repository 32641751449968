import {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectMapLayerIdByEntityName,
    selectSelectedPresetId,
    selectViewPortCoordinates,
} from "../../redux/selectors/selectors";
import {isErrorResponse} from "../../utils/utils";
import {useDispatch} from "react-redux";
import {entityApi} from "../../api/entityApi";
import {ViewPortCoordinates} from "../../api/entities/local/Borders";
import {BaseEntity, BaseEntityNameLess} from "../../api/entities/BaseEntity";
import {flyToFeatureAndHighlight} from "../../utils/mapUtils";
import {useEntityLoader} from "./useEntityLoader";
import {useMapFeatureLoader} from "./useMapFeatureLoader";


type Props = {
    entityName: string;
    serviceName: string;
    parentId?: string;
}

export type EntitiesLoaderReturnType<T extends BaseEntity | BaseEntityNameLess> = {
    loading: boolean;
    deleting: boolean;
    entities: T[];
    loadData: () => Promise<void>;
    deleteFn: ({entity}: { entity: T }) => Promise<any>;
    onRowClick: (entity: T) => Promise<void>;
    error: string | undefined;
    searchPhrase: string;
    setSearch: Dispatch<SetStateAction<string>>;
}

export const useEntitiesLoader = <T extends BaseEntity | BaseEntityNameLess>({
                                                                                 entityName,
                                                                                 serviceName,
                                                                                 parentId
                                                                             }: Props): EntitiesLoaderReturnType<T> => {
    const [loading, setLoading] = useState<boolean>(false);
    const [searchPhrase, setSearch] = useState<string>('');
    const [error, setError] = useState<string>();
    const [entities, setEntities] = useState<T[]>([]);
    const presetId = useTypedSelector(selectSelectedPresetId);
    const entityLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const viewPortCoordinates = useTypedSelector<ViewPortCoordinates>(selectViewPortCoordinates);
    const {loading: deleting, deleteFn: deleteEntity} = useEntityLoader<T>({entityName});
    const {loadFeature} = useMapFeatureLoader({entityName});
    const dispatch = useDispatch();
    const abortControllerRef = useRef(new AbortController());

    const loadData = useCallback(async () => {
        const {minLat, maxLat, minLon, maxLon} = viewPortCoordinates ?? {};

        if (minLat && maxLat && minLon && maxLon) {
            setLoading(true);
            try {

                const entities = await entityApi.getLeftPanelEntitiesList<T>({
                    entityName,
                    presetId,
                    parentId,
                    serviceName,
                    viewPort: viewPortCoordinates,
                    searchPhrase,
                    abortSignal: abortControllerRef.current.signal
                });
                if (!isErrorResponse(entities)) {
                    setEntities(entities);
                }
            } catch (e: any) {
                console.error('useEntitiesLoader.loadData error:', e);
                setError(e);
            }
            setLoading(false);
        }
    }, [entityName, presetId, serviceName, setLoading, viewPortCoordinates, searchPhrase])

    const onRowClick = useCallback(async ({id}: T) => {
        const {feature} = await loadFeature({entityId: id, entityName, findFeatureParent: !!parentId})
        if (feature) {
            await flyToFeatureAndHighlight({entityId: id, entityName, layerId: entityLayerId, feature, dispatch});
        }
    }, [entityName, entityLayerId])

    const deleteFn = useCallback(async ({entity}: { entity: T }) => {
        await deleteEntity({entity});
        await loadData();
    }, [deleteEntity, loadData])

    useEffect(() => {
        (async function () {
            const {minLat, maxLat, minLon, maxLon} = viewPortCoordinates ?? {};
            if (minLat && maxLat && minLon && maxLon) {
                await loadData();
            }
        })();
    }, [presetId, viewPortCoordinates, searchPhrase]);

    return {loading, deleting, entities, loadData, deleteFn, onRowClick, error, searchPhrase, setSearch};

};
