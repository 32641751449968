import React from "react";
import {Breadcrumbs} from "@mui/material";
import {useLocation, useMatches} from "react-router-dom";
import './breadcrumbs-by-routes.scss';
import LinkWrapper from "../LinkWrapper/LinkWrapper";


const BreadcrumbsByRoutes = () => {
    const {pathname: curPathname} = useLocation();

    const matches = useMatches()
        .filter((match) => Boolean(match.handle?.['crumb'] as any))
        .map(({handle, ...restMatch}: any) => ({
            ...restMatch,
            crumb: handle.crumb(restMatch.data)
        }));

    return (
        <div className="breadcrumbs">
            <Breadcrumbs aria-label="breadcrumb">
                {matches.map(({pathname, crumb}, index) => (
                    <LinkWrapper
                        className="breadcrumbs__item"
                        key={index}
                        to={pathname}
                        hasLink={pathname !== curPathname}
                        {...(crumb.testId ? {'data-testid': crumb.testId} : {})}
                    >
                        {crumb.name ?? crumb?.id}
                    </LinkWrapper>
                ))}
            </Breadcrumbs>
        </div>
    )
}

export default BreadcrumbsByRoutes;