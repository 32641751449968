import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import mapApi from "../../api/mapApi";
import {removeFeature, setFeatureWithParent} from "../../redux/entity/entity-reducer";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectFeatureWithParentByEntityName} from "../../redux/selectors/selectors";
import {isErrorResponse} from "../../utils/utils";
import {FeatureProperties} from "../../redux/map/types";


type LoadArgs = {
    entityId: string;
    entityName: string;
    findFeatureParent?: boolean;
}

export const loadFeatureWithParent = async ({entityId, entityName, findFeatureParent}: LoadArgs): Promise<{
    feature: GeoJSON.Feature<GeoJSON.Geometry, Omit<FeatureProperties, 'layerId'>> | null;
    parent?: { [key: string]: any } | null;
}> => {
    const response = {feature: null, parent: null} as { feature: any; parent?: any; };

    const abortController = new AbortController();
    const feature = await mapApi.getFeatureById(entityId, entityName, abortController.signal);
    if (!isErrorResponse(feature)) {
        response['feature'] = feature;
    }

    if (findFeatureParent) {
        const abortController = new AbortController();
        const parent = await mapApi.getFeatureById(entityId, entityName, abortController.signal, findFeatureParent);
        if (!isErrorResponse(parent)) {
            response['parent'] = parent;
        }
    }

    return response;
}

export const useMapFeatureLoader = ({entityName}) => {
    const featureWithParent = useTypedSelector(state => selectFeatureWithParentByEntityName(state, entityName));
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(removeFeature({entityName}));
        }
    }, []);

    const loadFeature = useCallback(async ({entityId, entityName, findFeatureParent}: LoadArgs): Promise<{
        feature: any;
        parent?: any;
    }> => {
        if (!featureWithParent?.[entityName]) {
            const resp = await loadFeatureWithParent({entityId, entityName, findFeatureParent});

            if (resp.feature) {
                dispatch(setFeatureWithParent({
                    entityName,
                    feature: resp.feature,
                    ...(findFeatureParent && resp.parent ? {parent: resp.parent} : {})
                }));
            }

            return resp;
        }

        return featureWithParent[entityName];
    }, [featureWithParent])

    return {featureWithParent, loadFeature};
}