import React, {useCallback, useEffect, useMemo} from "react";
import {
    Outlet,
    useParams
} from "react-router-dom";
import {useDispatch} from "react-redux";
import {isUuidString} from "../../../../utils/utils";
import {
    PublicTransitLayerEntityName,
    PublicTransitRouteEntity
} from "../../../../api/entities/replancity_PublicTransitLine";
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";
import {EntityView} from "../../../../api/entityApi";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import {
    removedFeaturesHighlighting,
    setSourceReloadRequired
} from "../../../../redux/map/map-reducer";
import {clearedSelectedMapFeatures} from "../../../../redux/showInfo/showInfoReducer";
import EntityManagerCleaner from "../../../../components/Entities/EntityManagerCleaner";
import {flyToFeatureAndHighlight} from "../../../../utils/mapUtils";
import {useMapFeatureLoader} from "../../../../hooks/entites/useMapFeatureLoader";


const TransitRoute = () => {
    const entityName = PublicTransitLayerEntityName.ROUTES;
    const entityView: EntityView = 'full';
    const dispatch = useDispatch();
    const routesLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, PublicTransitLayerEntityName.ROUTES));

    const {routeId} = useParams<any>();
    const existingRouteId = useMemo(() => isUuidString(routeId) ? routeId : '', [routeId]);

    const {entity, loadData} = useEntityLoader<PublicTransitRouteEntity>({entityName});
    const {loadFeature} = useMapFeatureLoader({entityName});

    const {defaultRouteProfile, defaultSchedule} = entity;
    const {id: routeProfileId} = defaultRouteProfile ?? {};
    const {id: scheduleId} = defaultSchedule ?? {};

    useEffect(() => {
        return () => {
            dispatch(clearedSelectedMapFeatures());
            dispatch(removedFeaturesHighlighting());
        }
    }, []);

    const loadRoute = useCallback(async (reloadLayer = false) => {
        await loadData({entityName, entityId: existingRouteId, entityView});

        //TODO remove when it's not required to reload after stop addition
        if (reloadLayer && routesLayerId) {
            dispatch(setSourceReloadRequired({sourceId: routesLayerId, isReloadRequired: true}));
        }
    }, [loadData, entityName, entityView, existingRouteId, routesLayerId]);

    useEffect(() => {
        (async function () {
            await loadRoute();

            if (existingRouteId) {
                const resp = await loadFeature({entityId: existingRouteId, entityName, findFeatureParent: true});

                if (resp.feature) {
                    await flyToFeatureAndHighlight({
                        entityId: existingRouteId,
                        entityName,
                        layerId: routesLayerId,
                        feature: resp.feature,
                        dispatch
                    });
                }
            }
        }());
    }, [loadRoute, existingRouteId, entityName, routesLayerId, dispatch])

    return (
        <>
            <EntityManagerCleaner entityName={entityName}>
                <Outlet context={{routeProfileId, scheduleId, loadRoute}}/>
            </EntityManagerCleaner>
        </>
    )
}

export default TransitRoute;