import React, {useCallback} from "react";
import {useParams} from "react-router-dom";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";


const RoadEdit = () => {
    const entityName = 'replancity_RoadInfrastructureRoadName';
    const layerToReloadEntityName = 'replancity_RoadInfrastructureSegment';
    const {roadId} = useParams<any>();
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, layerToReloadEntityName));

    const onSaveFn = useCallback((entity: any) => {
        if (roadId) {
            return `../../${entity.id}/edit`;
        }
        return `../${entity.id}/edit`;
    }, [roadId]);

    return (
        <EntityEditor
            entityId={roadId!}
            entityName={entityName}
            layerId={sourceId}
            presetProperty={'roadInfrastructurePreset'}
            getUrlOnSaveFn={onSaveFn}
        />
    )
}

export default RoadEdit;