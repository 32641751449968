import React, {useCallback} from "react";
import SimulationProgress from "../../components/ProjectSimulation/SimulationProgress/SimulationProgress";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import RunSelector from "../../components/RunSelector/RunSelector";
import {useRunsLoader} from "../../hooks/projects/useRunsLoader";
import {LoadingComponent} from "../../components/LoadingComponent/LoadingComponent";
import MapContainerWithSources from "../../containers/map/MapContainer/MapContainerWithSources";
import SidePanelManager from "../../components/LayersManager/SidePanelManager";
import LayersTimeSelectors from "../../components/map/LayersTimeSelectors";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";
import RunsToCompareSelector from "../../components/RunSelector/RunsToCompareSelector";
import {useDispatch} from "react-redux";
import {MapboxGeoJSONFeatureWithProperties} from "../../redux/map/types";
import {setSelectedMapFeatures} from "../../redux/showInfo/showInfoReducer";
import {SelectedFeatureProperties} from "../../redux/showInfo/types";


const LayersModePage = () => {
    const {loading, loadRuns} = useRunsLoader();
    const dispatch = useDispatch();

    //TODO this is temporary to fix SidePAnel opening
    const mapClickListener = useCallback((event: mapboxgl.MapLayerMouseEvent, isDrawing?: (boolean | undefined)) => {
        const {features} = event;
        if (features?.length) {
            const selectedFeaturesProperties = features.map(feature => {
                    const {
                        properties: {
                            id,
                            featureStateId,
                            layerId,
                            entityName
                        }
                    } = feature as MapboxGeoJSONFeatureWithProperties;
                    return {id, featureStateId: featureStateId.toString(), layerId, entityName};
                }
            ) satisfies SelectedFeatureProperties[];
            dispatch(setSelectedMapFeatures(selectedFeaturesProperties));
        }
    }, [])

    return (
        <ProjectPage
            subHeaderLeft={
                <>
                    <RunSelector/>
                    <SimulationProgress simulationCompletedFn={loadRuns}/>
                    <RunsToCompareSelector/>
                </>
            }
            main={
                <LoadingComponent isLoading={loading}>
                    <MapContainerWithSources handleMapClick={mapClickListener}/>
                </LoadingComponent>
            }
            leftPanel={
                <LayersListWrapper>
                    <LayersTimeSelectors/>
                </LayersListWrapper>
            }
            // leftPanelTitle={t('map.visible-layers-title')}
            rightPanel={<SidePanelManager/>}
        />
    )
}

export default LayersModePage;