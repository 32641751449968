import React from "react";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import IFrameComponent from "../../components/IFrameComponent";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../redux/selectors/selectors";
import {ENV} from "../../utils/env";


const RunsPage = () => {
  const projectId = useTypedSelector(selectCurrentProjectId);

  return (
      <ProjectPage
          main={
            <IFrameComponent
                url={`${ENV.REACT_APP_API_HOST}/app/open?screen=replancity_ProjectRunList.edit&item=replancity_Project-${projectId}`}
            />
          }
      />
  )
}

export default RunsPage;