import React from "react";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import ProjectPresetsSelector from "../../components/ProjectPresetsSelector/ProjectPresetsSelector";
import * as SelectBox from "../../components/form/SelectBox/SelectBox";
import {Row} from "../../components/form/Form/Form";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectPresetType, selectSelectedPresetId} from "../../redux/selectors/selectors";
import MapContainerWithSources from "../../containers/map/MapContainer/MapContainerWithSources";
import TransitManager from "./TransitManagement/TransitManager";
import {EntityServiceName} from "../../api/enums/enums";
import TraceRoutesToNetwork from "../../components/TraceRoutesToNetwork/TraceRoutesToNetwork";
import {useNavigationOnFeaturesSelection} from "../../hooks/transitlLines/useNavigationOnFeaturesSelection";
import {copyModeOptions, NEW_ROUTE_SOURCE} from "./constants";
import {GeoJsonGeometryTypes} from "geojson";


const getUrlFunc = ({parentId, childId, featureType}: {
    parentId?: string | number;
    childId: string | number;
    featureType: GeoJsonGeometryTypes
}) => {
    return featureType === 'Point' ? `./stops/${childId}/edit` : `./${parentId}/${childId}`;
}


const PublicTransitEditorModePage = () => {
    const presetEntityName = 'replancity_PublicTransitPreset';
    const presetId = useTypedSelector(selectSelectedPresetId);
    const currentPresetType = useTypedSelector(selectPresetType);
    const {t} = useTranslation();

    const {navigateOnFeatureSelection} = useNavigationOnFeaturesSelection({getUrlFunc, findParent: true});

    return (
        <ProjectPage
            showSkeleton={!presetId || presetEntityName !== currentPresetType}
            skeletonExplanation={t('common.presets-absence-error-toast')}
            subHeaderLeft={
                <>
                    <ProjectPresetsSelector
                        presetEntityName={presetEntityName}
                        serviceName={EntityServiceName.PUBLIC_TRANSIT}
                        copySpinnerMsg={t('transit-lines.preset-copy-spinner-msg')}
                        formFields={
                            <Row>
                                <SelectBox.Labeled
                                    label={t('presets.add-preset-modal-copy-mode')}
                                    name='copyMode'
                                    options={copyModeOptions}
                                    required
                                />
                            </Row>
                        }
                    />
                    <TraceRoutesToNetwork/>
                </>
            }
            main={
                <MapContainerWithSources
                    mapConfigType={EntityServiceName.PUBLIC_TRANSIT}
                    defaultSources={NEW_ROUTE_SOURCE}
                    isDrawerEnabled={true}
                    editableFeatureTypes={{LineString: false, MultiLineString: false}}
                    handleMapClick={navigateOnFeatureSelection}
                />
            }
            leftPanel={<TransitManager/>}
            leftPanelTitle={t('transit-lines.transit-lines-editor')}
        />
    )
}

export default PublicTransitEditorModePage;