import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MarkerPoint} from "./types";


type State = {
    fromPoint: MarkerPoint | null;
    toPoint: MarkerPoint | null;
}

export const initialState: State = {
    fromPoint: null,
    toPoint: null
}

const roadRouteCheckSlice = createSlice({
    name: 'roadRouteCheck',
    initialState,
    reducers: {
        setRoadRouteCheckPoint(state, action: PayloadAction<any>) {
            const point = action.payload;
            return {
                ...state,
                ...point
            };
        },
        cleanRoadRouteCheckPoints() {
            return {
                ...initialState
            };
        },
    },
});

export const {
    setRoadRouteCheckPoint,
    cleanRoadRouteCheckPoints
} = roadRouteCheckSlice.actions;

export default roadRouteCheckSlice.reducer
